import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions, ActivityIndicator } from 'react-native';
import { Image } from 'expo-image';
import UserCircle from './UserCircle';
import theme from '../styles/theme';

const { width: SCREEN_WIDTH } = Dimensions.get('window');
const MAX_WIDTH = 600;
const CARD_WIDTH = Math.min(SCREEN_WIDTH, MAX_WIDTH);
const CARD_HEIGHT = 150; // Fixed height for horizontal layout
const IMAGE_SIZE = CARD_HEIGHT;

const HorizontalCard = ({ card, onPress }) => {
  const isWorld = card?.content_type === 'world'; // Safely access card properties

  return (
    <TouchableOpacity onPress={onPress} disabled={!card || isWorld}>
      <View style={styles.cardContainer}>
        {!card ? (
          // <ActivityIndicator
          //   size="large"
          //   color={theme.colors.redPrimary}
          //   style={styles.loadingIndicator}
          // />
          <View></View>
        ) : (
          <View style={styles.card}>
            {!isWorld && (
              <View style={styles.imageContainer}>
                <Image
                  source={{ uri: card.art }}
                  style={styles.image}
                  contentFit="cover"
                />
                <View style={styles.userCircleContainer}>
                  <UserCircle
                    profile_uuid={card.creator_uuid}
                    size={35}
                    borderColor={theme.colors.beige}
                  />
                </View>
              </View>
            )}
            <View
              style={isWorld ? styles.worldContentContainer : styles.contentContainer}>
              {!isWorld && (
                <Text style={styles.title} numberOfLines={2} adjustsFontSizeToFit>
                  {card.name}
                </Text>
              )}
              <Text style={styles.flavor} numberOfLines={isWorld ? 6 : 4}>
                {isWorld && !card.processed_flavor
                  ? "When there are 4 or more cards in your world, it will automatically get a title and a world summary."
                  : card.processed_flavor}
              </Text>
            </View>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: CARD_WIDTH,
    height: CARD_HEIGHT + 2 * StyleSheet.hairlineWidth,
    alignSelf: 'center',
    backgroundColor: theme.colors.gray,
    zIndex: 20,
  },
  card: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: theme.colors.gray,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.beige,
    overflow: 'hidden',
  },
  imageContainer: {
    width: IMAGE_SIZE,
    height: CARD_HEIGHT,
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  userCircleContainer: {
    position: 'absolute',
    left: theme.spacings.xsmall,
    bottom: theme.spacings.xsmall,
  },
  contentContainer: {
    flex: 1,
    padding: theme.spacings.small,
    justifyContent: 'flex-start',
  },
  worldContentContainer: {
    flex: 1,
    padding: theme.spacings.medium,
    justifyContent: 'center', // Center the text for 'world' type
    alignItems: 'center',
  },
  title: {
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.scaledSizes.large,
    marginBottom: theme.spacings.xsmall,
  },
  flavor: {
    color: theme.colors.white,
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.scaledSizes.small,
    // textAlign: 'center', // Center-align the text for better presentation
  },
});

export default HorizontalCard;
