import React, {useContext, useEffect, useRef, useState} from 'react';
import { View, StyleSheet, Text, SafeAreaView } from 'react-native';
import BouncingSpurs from "../components/BouncingSpurs";
import WhimsicalMessages from "../components/WhimsicalMessages";
import { checkTaskData, fetchCardByUUID } from "../utils/api/cardsService";
import { useQueryClient } from "react-query";
import { useProfile } from "../contexts/ProfileContext";
import alert from "../utils/alert";
import theme from '../styles/theme';
import CreatingFabylCard from '../components/CreatingFabylCard';
import MiniCard from "../components/MiniCard";
import SvgRiffLine from "../assets/images/RiffLine";
import {AuthContext} from "../contexts/AuthContext";
import { CommonActions } from '@react-navigation/native';
import SpeedIndicator from "../components/SpeedIndicator";


// Add this component
const SlowStyleWarning = () => (
  <View style={styles.warningContainer}>
    <SpeedIndicator speed="slow" color={theme.colors.gray}/>
    <Text style={styles.warningText}>
      This style takes a little longer...{'\n'}
      but it's worth the wait (hopefully)!
    </Text>
  </View>
);

const FabylCreationScreen = ({ route, navigation }) => {
  const { fabylName, selectedFriend, selectedWorld, cardRiffedFrom, task_id, uuid } = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isSlowImplementation, setIsSlowImplementation] = useState(false);
  const pollIntervalRef = useRef(null);
  const isMounted = useRef(true);
  const queryClient = useQueryClient();
  const { refetchProfileData, markFirstRiff } = useProfile();
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    // Reset state on mount
    setIsLoading(true);
    setProgress(0);
    isMounted.current = true;

    // Start polling
    pollCreationProgress();

    // Cleanup
    return () => {
      isMounted.current = false;
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
        pollIntervalRef.current = null;
      }
    };
  }, [task_id]); // Add task_id as dependency

  const pollCreationProgress = async () => {
    // Clear any existing interval
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }

    const checkProgress = async () => {
      if (!isMounted.current) return;

      try {
        const taskData = await checkTaskData(task_id);

        if (!isMounted.current) return;

        if (taskData.status === 'SUCCESS') {
          clearInterval(pollIntervalRef.current);
          pollIntervalRef.current = null;

          const cardData = taskData.meta.card_data;
          queryClient.invalidateQueries('cards');
          queryClient.invalidateQueries('worlds');

          const screenName = isAuthenticated ? 'CardDetail' : 'PublicCardDetail';

          if (cardRiffedFrom) {
            // Reset to CardDetailScreen of cardRiffedFrom and then the new CardDetailScreen
            console.log(`Resetting to CardDetailScreen of cardRiffedFrom: ${cardRiffedFrom.uuid}`)
            navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  { name: screenName, params: { card: cardRiffedFrom, startAtTop: false } },
                  { name: screenName, params: { card: cardData } },
                ],
              })
            );
          }
          else if (selectedWorld) {
            // Reset to WorldDetailScreen of selectedWorld and then the new CardDetailScreen
            console.log(`Resetting to WorldDetailScreen of selectedWorld: ${selectedWorld.uuid}`)
            navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  { name: 'WorldDetail', params: { world_uuid: selectedWorld.uuid } },
                  { name: screenName, params: { card: cardData } },
                ],
              })
            );
          }
          else {
            // Reset to CreateScreen and then the new CardDetailScreen
            console.log(`Resetting to CreateScreen and then CardDetailScreen: ${cardData.uuid}`)
            navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  { name: 'CreateScreen' },
                  { name: screenName, params: { card: cardData } },
                ],
              })
            );
          }
          navigation.navigate(screenName, { card: cardData });

        } else if (taskData.status === 'FAILURE') {
          throw new Error("Generation failed");
        } else if (taskData.status === 'PROGRESS') {
          setProgress(taskData.meta.total);
          // Check for slow implementation
          if (taskData.meta?.implementation_speed === 'slow') {
            setIsSlowImplementation(true);
          }
        }
      } catch (error) {
        if (isMounted.current) {
          handleError(error);
        }
      }
    };

    // Do initial check
    await checkProgress();

    // Set up interval for subsequent checks
    if (isMounted.current) {
      pollIntervalRef.current = setInterval(checkProgress, 1000);
    }
  };

  const handleError = (error) => {
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }
    setIsLoading(false);
    alert("Error", error.message || "An error occurred. Please try again.");
    navigation.setParams({ returnFromCreation: true });
    navigation.goBack();
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        {/*<Text style={styles.title}>creating your fabyl...</Text>*/}
        {cardRiffedFrom && (
          <View style={styles.miniCardContainer}>
            <MiniCard item={cardRiffedFrom} pressable={false} />
            <SvgRiffLine style={styles.riffLine} />
          </View>
        )}
        <CreatingFabylCard fabylName={fabylName} />
        <BouncingSpurs isAnimating={isLoading} progress={progress} />
        <WhimsicalMessages isAnimating={isLoading} progress={progress} />
      </View>
      {isSlowImplementation && <SlowStyleWarning />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.gray,
    marginBottom: theme.spacings.large,
    fontFamily: theme.fonts.family.regular,
  },
  miniCardContainer: {
    alignItems: 'center',
    // marginBottom: theme.spacings.medium,
  },
  riffLine: {
    marginVertical: theme.spacings.small,
  },
  warningContainer: {
    position: 'absolute',
    bottom: theme.spacings.xxlarge, // Adjust this value based on where you want it
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacings.small,
    opacity: 0.8,
    width: '100%',
    paddingHorizontal: theme.spacings.medium,
  },
  warningText: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.gray,
    fontFamily: theme.fonts.family.regular,
    textAlign: 'center',
  },
});

export default FabylCreationScreen;
