import React, {useMemo, useState} from 'react';
import {View} from 'react-native';
import Svg, {Circle, Line} from 'react-native-svg';
import * as d3 from 'd3-hierarchy';
import {
  Gesture,
  GestureDetector,
  GestureHandlerRootView,
} from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue, withDelay,
  withTiming,
} from 'react-native-reanimated';
import CardTileForTree, {CARD_SIZE} from './CardTileForTree';
import WorldTileForTree, {WORLD_TILE_SIZE} from "./WorldTileForTree";
import theme from '../styles/theme';
import {useFocusEffect} from "@react-navigation/native";

const TreeGraph = ({
                     data,
                     onCardPress,
                     containerWidth,
                     containerHeight,
                     selectedCardUuid = null // This is the INITIAL card to center on
                   }) => {
  console.log('\n=== Tree Graph Initialization ===');
  console.log('Container Dimensions:', {containerWidth, containerHeight});
  const cardRadius = CARD_SIZE / 2;
  const horizontalSpacing = CARD_SIZE * 1.2;
  const verticalSpacing = CARD_SIZE * 1.5;
  const padding = CARD_SIZE / 2 + theme.spacings.xsmall; // Add padding around the tree
  const [currentlySelectedUuid, setCurrentlySelectedUuid] = useState(selectedCardUuid);

  // Add a wheel event handler for mouse-based zoom
  // This is pretty jenky and does not focus in correct location...but I cant keep doing this right now.
  const handleWheel = (event) => {
    const zoomFactor = 0.1;
    const newScale = Math.max(0.5, Math.min(3, scale.value - event.deltaY * zoomFactor / 100));

    // Update the scale value
    scale.value = newScale;
  };

  // Animation values
  const scale = useSharedValue(1);
  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);

  // Keep the tree layout calculation in useMemo
  const {contentWidth, contentHeight, nodes, links, rootNode} = useMemo(() => {
    const treeRoot = d3.hierarchy(data, d => d.children || []);
    const treeLayout = d3.tree().nodeSize([horizontalSpacing, verticalSpacing]);
    treeLayout(treeRoot);

    let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
    treeRoot.each(node => {
      minX = Math.min(minX, node.x);
      maxX = Math.max(maxX, node.x);
      minY = Math.min(minY, node.y);
      maxY = Math.max(maxY, node.y);
    });

    const width = maxX - minX + padding * 2;
    const height = maxY - minY + padding * 2;
    const offsetX = -minX + padding;
    const offsetY = -minY + padding;

    const rawNodes = treeRoot.descendants();
    const adjustedNodes = rawNodes.map(node => ({
      ...node,
      x: node.x + offsetX,
      y: node.y + offsetY,
    }));

    // (3) Fix each adjusted node's "parent" property
    //     so it points to the *adjusted* parent.
    adjustedNodes.forEach(adjustedNode => {
      if (adjustedNode.parent) {
        // find the corresponding adjusted parent
        const parentUuid = adjustedNode.parent.data.uuid;
        const matchingParent = adjustedNodes.find(n => n.data.uuid === parentUuid);

        // Now reassign the parent to the correct adjusted node
        adjustedNode.parent = matchingParent || null;
      }
    });

    const adjustedLinks = treeRoot.links().map(link => ({
      sourceX: link.source.x + offsetX,
      sourceY: link.source.y + offsetY,
      targetX: link.target.x + offsetX,
      targetY: link.target.y + offsetY,
    }));

    return {
      contentWidth: width,
      contentHeight: height,
      nodes: adjustedNodes,
      links: adjustedLinks,
      rootNode: adjustedNodes[0],
    };
  }, [data, containerWidth, containerHeight]);

  // Move centering logic into useFocusEffect
  useFocusEffect(
    React.useCallback(() => {
      // Small delay to ensure layout is complete
      requestAnimationFrame(() => {

        const selectedNode = nodes.find((node) => node.data.uuid === selectedCardUuid);
        const centerNode = selectedNode || rootNode;

        // Update selected node
        setCurrentlySelectedUuid(selectedCardUuid);
        onCardPress(selectedNode?.data || rootNode.data);

        const initialScale = 1.0;
        const initialTranslateX = (containerWidth / 2 - centerNode.x);
        const initialTranslateY = selectedNode ? (containerHeight / 2 - centerNode.y) : 0;

        scale.value = withTiming(initialScale, {duration: 100});
        translateX.value = withTiming(initialTranslateX, {duration: 100});
        translateY.value = withTiming(initialTranslateY, {duration: 100});
      });
    }, [nodes, selectedCardUuid, containerWidth, containerHeight, rootNode])
  );

  // Modify the card press handler to update the currently selected card
  const handleCardPress = (card) => {
    setCurrentlySelectedUuid(card.uuid);
    onCardPress(card);
  };

  // Determine if a card is selected (for highlighting)
  const isCardSelected = (cardUuid) => cardUuid === currentlySelectedUuid;

// First add a debug function
const debugPath = (selectedNode) => {
  console.log('Selected Node:', {
    uuid: selectedNode.data.uuid,
    x: selectedNode.x,
    y: selectedNode.y
  });

  let path = [];
  let current = selectedNode;
  while (current.parent) {
    path.push({
      fromX: current.parent.x,
      fromY: current.parent.y,
      toX: current.x,
      toY: current.y
    });
    current = current.parent;
  }
  console.log('Path coordinates:', path);
};

// Then modify the path checking function
const isLinkInSelectedPath = (link) => {
  if (!currentlySelectedUuid) return false;

  const selectedNode = nodes.find(n => n.data.uuid === currentlySelectedUuid);
  if (!selectedNode) return false;

  // Create a path from selected node to root
  let currentNode = selectedNode;
  while (currentNode.parent) {
    // Check if this link connects currentNode to its parent
    if (
      (Math.abs(link.sourceX - currentNode.parent.x) < 1 &&
       Math.abs(link.sourceY - currentNode.parent.y) < 1 &&
       Math.abs(link.targetX - currentNode.x) < 1 &&
       Math.abs(link.targetY - currentNode.y) < 1) ||
      (Math.abs(link.sourceX - currentNode.x) < 1 &&
       Math.abs(link.sourceY - currentNode.y) < 1 &&
       Math.abs(link.targetX - currentNode.parent.x) < 1 &&
       Math.abs(link.targetY - currentNode.parent.y) < 1)
    ) {
      return true;
    }
    currentNode = currentNode.parent;
  }
  return false;
};

  const renderLinks = () => {
  const selectedNode = nodes.find(n => n.data.uuid === currentlySelectedUuid);
  if (selectedNode) {
    debugPath(selectedNode);
  }

  return links.flatMap((link, index) => {
    const isSelected = isLinkInSelectedPath(link);

    // Render a gray shadow for selected links
    const shadowLine = isSelected ? (
      <Line
        key={`shadow-${index}`}
        x1={link.sourceX}
        y1={link.sourceY + cardRadius}
        x2={link.targetX}
        y2={link.targetY - cardRadius}
        stroke={theme.colors.gray} // Use gray for the shadow
        strokeWidth={5} // Wider than the yellow line
        opacity={1} // Slightly transparent shadow
      />
    ) : null;

    // Render the actual link line
    const mainLine = (
      <Line
        key={`line-${index}`}
        x1={link.sourceX}
        y1={link.sourceY + cardRadius}
        x2={link.targetX}
        y2={link.targetY - cardRadius}
        stroke={isSelected ? theme.colors.yellowPrimary : theme.colors.redPrimary}
        strokeWidth={isSelected ? 4 : 2} // Keep yellow thinner
        strokeDasharray={isSelected ? "" : "15,5"}
        opacity={isSelected ? 1 : 1}
      />
    );

    // Return both shadow and main line
    return isSelected ? [shadowLine, mainLine] : [mainLine];
  });
};

  // Create gesture builders
  // We'll keep offsets for pan gestures
  const panOffsetX = useSharedValue(0);
  const panOffsetY = useSharedValue(0);
  // const isPinching = useSharedValue(false);
  const isPinching = useSharedValue(0);

const panGesture = Gesture.Pan()
  .onBegin(() => {
    if (isPinching.value === 0) {
      panOffsetX.value = translateX.value;
      panOffsetY.value = translateY.value;
    }
  })
  .onUpdate(event => {
    if (isPinching.value === 0) {
      // Adjust translation based on current scale
      // When zoomed out (scale < 1), movement will be more pronounced
      // When zoomed in (scale > 1), movement will be less pronounced
      translateX.value = panOffsetX.value + (event.translationX / scale.value);
      translateY.value = panOffsetY.value + (event.translationY / scale.value);
    }
  });

  // We'll keep a start scale for pinch gestures
  const startScale = useSharedValue(1);
  const focalX = useSharedValue(0);
  const focalY = useSharedValue(0);

const pinchGesture = Gesture.Pinch()
  .onBegin((event) => {
    // isPinching.value = true;
    isPinching.value = withTiming(1, { duration: 0 });
    startScale.value = scale.value;
    focalX.value = event.focalX;
    focalY.value = event.focalY;
  })
  .onUpdate((event) => {
    scale.value = startScale.value * event.scale;
    focalX.value = event.focalX;
    focalY.value = event.focalY;
  })
  .onEnd(() => {
    // scale.value = withTiming(1);
    // isPinching.value = false;
    startScale.value = scale.value;
    // isPinching.value = false
    isPinching.value = withDelay(
      100,
      withTiming(0, {duration: 0})
    );
  });

  // Combine gestures so they can both be recognized simultaneously
  // const combinedGesture = Gesture.Simultaneous(panGesture, pinchGesture);
  const combinedGesture = Gesture.Simultaneous(panGesture, pinchGesture);

  // Animated style for the container
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: focalX.value }, // Translate to focal point
      { translateY: focalY.value },
      { translateX: -contentWidth / 2 }, // Center the view
      { translateY: -contentHeight / 2 },
      { scale: scale.value }, // Apply scale
      { translateX: -focalX.value }, // Translate back
      { translateY: -focalY.value },
      { translateX: contentWidth / 2 }, // Recenter
      { translateY: contentHeight / 2 },
        // {scale: scale.value},
        {translateX: translateX.value},
        {translateY: translateY.value},
      ],
    };
  });

  return (
    <GestureHandlerRootView style={{flex: 1}}>
      <GestureDetector gesture={combinedGesture} >
        <View style={{ flex: 1, backgroundColor: 'transparent' }}>
          <Animated.View
            onWheel={handleWheel}
            style={[
              {
                width: contentWidth,
                height: contentHeight,
                backgroundColor: 'transparent',
                // borderWidth: 5,
                // borderColor: 'red'
              },
              animatedStyle,
            ]}
          >
            {/*<Svg width={contentWidth} height={contentHeight} style={{ position: 'absolute' }}>*/}
            <Svg
              width={contentWidth}
              height={contentHeight}
              style={{
                position: 'absolute',
                // top: (containerHeight - contentHeight) / 2,
                // left: (containerWidth - contentWidth) / 2,
                // borderWidth: 5,
                // borderColor: 'green',
                backgroundColor: 'transparent',
              }}
            >
              {renderLinks()}
            </Svg>

            <Circle
              cx={contentWidth / 2} // Replace with the calculated center's X
              cy={contentHeight / 2} // Replace with the calculated center's Y
              r={200} // Radius of the black circle
              fill="black"
            />

            {nodes.map((node, index) => {
              const {content_type} = node.data;

              return (
                <View
                  key={index}
                  style={{
                    position: 'absolute',
                    top: node.y - (content_type === "world" ? WORLD_TILE_SIZE / 2 : CARD_SIZE / 2),
                    left: node.x - (content_type === "world" ? WORLD_TILE_SIZE / 2 : CARD_SIZE / 2),
                  }}
                >
                  {content_type === "world" ? (
                    <WorldTileForTree
                      item={{
                        thumbnail: node.data.thumbnail,
                        name: node.data.name,
                        processed_flavor: node.data.processed_flavor,
                      }}
                      onPress={() => handleCardPress(node.data)}
                    />
                  ) : (
                    <CardTileForTree
                      item={{
                        uuid: node.data.uuid,
                        thumbnail: node.data.thumbnail,
                        creator_uuid: node.data.creator_uuid,
                      }}
                      onPress={() => handleCardPress(node.data)}
                      isSelected={isCardSelected(node.data.uuid)}
                    />
                  )}
                </View>
              );
            })}
          </Animated.View>
        </View>
      </GestureDetector>
    </GestureHandlerRootView>
  );
};

export default TreeGraph;
