import React, { memo, useContext } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { Image } from 'expo-image';
import UserCircle from "./UserCircle";
import { useNavigation } from '@react-navigation/native';
import theme from "../styles/theme";
import { AuthContext } from "../contexts/AuthContext";

export const CARD_SIZE = 80; // Fixed card size

const CardTileForTree = ({ item, showUsername = true, onPress, isSelected = false }) => {
    const navigation = useNavigation();
    const { uuid, creator_uuid } = item;
    const { isAuthenticated } = useContext(AuthContext);

    const handlePress = () => {
      // 1. If the card is already selected, skip onPress and go straight to navigation
      if (isSelected) {
        if (isAuthenticated) {
          navigation.push('CardDetail', {card_uuid: item.uuid});
        } else {
          navigation.push('PublicCardDetail', {card_uuid: item.uuid});
        }
        return;
      }

      // 2. Otherwise, use the original flow
      if (onPress) {
        onPress(item);
      } else {
        if (isAuthenticated) {
          navigation.push('CardDetail', {card_uuid: item.uuid});
        } else {
          navigation.push('PublicCardDetail', {card_uuid: item.uuid});
        }
      }
    };

    return (
        <TouchableOpacity onPress={handlePress}>
          <View style={[
            styles.cardContainer,
            isSelected && styles.selectedCard
          ]}>
                <Image
                    source={{ uri: item.thumbnail || item.art }}
                    style={styles.cardImage}
                />
                {showUsername && creator_uuid && (
                    <View style={styles.username}>
                        <UserCircle
                            profile_uuid={creator_uuid}
                            size={23}
                            borderColor={theme.colors.white}
                            borderWidth={0.75}
                        />
                    </View>
                )}
            </View>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    cardContainer: {
        width: CARD_SIZE,
        height: CARD_SIZE,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.borderRadius.medium, // Adds border rounding
        borderWidth: 1, // Base border width
        borderColor: theme.colors.gray, // Base border color (outer gray border)
        backgroundColor: theme.colors.white, // Background color for clarity
        overflow: 'hidden', // Ensure rounded corners work
    },
    selectedCard: {
        borderWidth: 2 * StyleSheet.hairlineWidth, // Inner yellow border width
        borderColor: theme.colors.gray, // Inner yellow border color
        padding: 3, // Extra padding to simulate outer border
        backgroundColor: theme.colors.yellowPrimary, // Outer border color using background
    },
    cardImage: {
        width: '100%',
        height: '100%',
        resizeMode: 'cover', // Ensure the image covers the card area
    },
    username: {
        position: 'absolute',
        bottom: theme.spacings.xsmall,
        left: theme.spacings.xsmall,
    },
});


export default memo(CardTileForTree);
