import React, { memo } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import theme from "../styles/theme";
import {CARD_SIZE} from "./CardTileForTree";

// export const WORLD_TILE_SIZE = 100; // Fixed square size for the world tile
export const WORLD_TILE_SIZE = CARD_SIZE

const WorldTileForTree = ({ item, onPress, isSelected=false }) => {
    const handlePress = () => {
        if (onPress) {
            onPress(item); // Call the passed onPress prop with the world data
        }
    };

    return (
        <TouchableOpacity onPress={handlePress}>
            <View style={styles.worldContainer}>
                <Ionicons
                    name="planet-outline"
                    size={WORLD_TILE_SIZE / 2.5} // Scale icon size relative to tile size
                    color={theme.colors.yellowPrimary} // Use yellowPrimary for icon
                />
            </View>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    worldContainer: {
        // width: WORLD_TILE_SIZE * (16 / 9),
        width: WORLD_TILE_SIZE,
        height: WORLD_TILE_SIZE,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.borderRadius.medium,
        borderWidth: 1,
        borderColor: theme.colors.beige, // Gray border for contrast
        backgroundColor: theme.colors.gray, // Gray background
        overflow: 'hidden', // Ensure rounded corners work
    },
});

export default memo(WorldTileForTree);
