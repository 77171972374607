import { authenticatedAxiosCall } from "./tokenService";
import axios from "axios";
import API_ENDPOINTS from "../../apiEndpoints";
import {getDeviceId} from "../secureStore";

async function fetchProfileData() {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.PROFILE);
  return response.data;
}

async function fetchSubscriptionData() {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.SUBSCRIPTION);
  return response.data;
}

async function fetchPublicProfileData(profileUuid) {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.generate.PUBLIC_PROFILE(profileUuid));
  return response.data;
}

export async function fetchMinimalProfileData(profileUuid) {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.generate.MINIMAL_PROFILE(profileUuid));
  return response.data;
}

async function signUp(signUpData) {

  // Construct the payload to match the expected structure for dj-rest-auth
  const payload = {
    username: signUpData.username,
    email: signUpData.email,
    password1: signUpData.password1,
    password2: signUpData.password2,
  };

  try {
    const response = await axios.post(API_ENDPOINTS.SIGNUP, payload);
    // Log the response from the server
    return response.data;
  } catch (error) {
    // Log the error
    console.error('Sign Up Error:', error.response ? error.response.data : error.message);
    throw error; // You may want to handle this error more gracefully
  }
}

async function resetPassword(email) {
  console.log(`Requesting password reset...`);
  const response = await axios.post(API_ENDPOINTS.PASSWORD_RESET, {email});
  return response.data;
}


async function getConfig() {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.CONFIG);
  return response.data;
}


async function deactivateDjangoAccount() {
  try {
    console.log(`Deactivating account...`);
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.DEACTIVATE);
    return response.data;
  } catch (error) {
    // Check if the error response has a specific format or message for invalid password
    throw error;
  }
}

async function reactivateDjangoAccount() {
  console.log(`Reactivating account...`);
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.REACTIVATE);
  return response.data;
}


async function sendFriendRequest(profileUuid) {
  try {
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.generate.SEND_FRIEND_REQUEST(profileUuid));
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data.message === 'Friend request already sent') {
      throw new Error('Friend request already sent');
    }
    throw error;
  }
}


export async function fetchFriendRequests(page=1) {
  const response = await authenticatedAxiosCall('get', `${API_ENDPOINTS.FRIEND_REQUESTS}?page=${page}`);
  return response.data;
}

export async function fetchFriendRequestsCount() {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.FRIEND_REQUESTS_COUNT);
  return response.data;
}

async function acceptFriendRequest(profileUuid) {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.generate.ACCEPT_FRIEND_REQUEST(profileUuid));
  return response.data;
}

async function deleteFriendRequest(profileUuid) {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.generate.DELETE_FRIEND_REQUEST(profileUuid));
  return response.data;
}

async function rejectFriendRequest(friendRequestId) {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.generate.REJECT_FRIEND_REQUEST(friendRequestId));
  return response.data;
}

async function removeFriend(profileUuid) {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.generate.REMOVE_FRIEND(profileUuid));
  return response.data;
}

async function checkIsFriend(profileUuid) {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.generate.IS_FRIEND(profileUuid));
  return response.data;
}

async function fetchNotifications(page = 1) {
  const response = await authenticatedAxiosCall('get', `${API_ENDPOINTS.NOTIFICATIONS}?page=${page}`);
  return response.data;
}


export async function fetchUnreadNotifications() {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.UNREAD_NOTIFICATIONS);
  return response.data;
}


export async function fetchReadNotifications() {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.READ_NOTIFICATIONS);
  return response.data;
}


async function deleteNotification(notificationId) {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.generate.DELETE_NOTIFICATION(notificationId));
  return response.data;
}


export async function markNotificationAsRead(notificationId) {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.generate.MARK_AS_READ(notificationId));
  return response.data;
}

export async function markNotificationAsUnread(notificationId) {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.generate.MARK_AS_UNREAD(notificationId));
  return response.data;
}


export async function markAllNotificationsAsRead() {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.MARK_ALL_READ);
  return response.data;
}

export async function fetchUnreadNotificationCount() {
  const response = await authenticatedAxiosCall('get', API_ENDPOINTS.UNREAD_NOTIFICATION_COUNT);
  return response.data;
}


export async function toggleBlock(profileUuid) {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.generate.BLOCK_USER(profileUuid));
  return response.data;
}


export async function updateTutorialSeen() {
  try {
    const payload = { tutorial_seen: true };
    const response = await authenticatedAxiosCall('patch', API_ENDPOINTS.UPDATE_TUTORIAL_SEEN, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating tutorial seen flag:', error);
    // Check if the error response contains the expected data
    if (error.response && error.response.data) {
      // Extract and throw new error with the server's response
      throw new Error(Object.values(error.response.data).join(", "));
    } else {
      // If the response data is not in the expected format, throw a generic error
      throw new Error("An error occurred while updating the tutorial seen flag.");
    }
  }
}


export async function updateDisclaimerAcknowledged() {
  try {
    const payload = { disclaimer_acknowledged: true };
    const response = await authenticatedAxiosCall('patch', API_ENDPOINTS.UPDATE_DISCLAIMER_ACKNOWLEDGED, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating disclaimer acknowledged flag:', error);
    // Check if the error response contains the expected data
    if (error.response && error.response.data) {
      // Extract and throw new error with the server's response
      throw new Error(Object.values(error.response.data).join(", "));
    } else {
      // If the response data is not in the expected format, throw a generic error
      throw new Error("An error occurred while updating the disclaimer acknowledged flag.");
    }
  }
}


// userService.js
export const updateProfileBoolFlag = async (endpoint, fieldName) => {
  try {
    const payload = { [fieldName]: true };
    const response = await authenticatedAxiosCall('patch', endpoint, payload);
    return response.data;
  } catch (error) {
    console.error(`Error updating ${fieldName}:`, error);
    throw new Error(`An error occurred while updating ${fieldName}.`);
  }
};

export const updateCreateTooltipSeen = () =>
  updateProfileBoolFlag(API_ENDPOINTS.UPDATE_CREATE_TOOLTIP_SEEN, 'create_tooltip_seen');

export const updateFirstCard = () =>
  updateProfileBoolFlag(API_ENDPOINTS.UPDATE_FIRST_CARD, 'first_card_created');

export const updateRiffTooltipSeen = () =>
  updateProfileBoolFlag(API_ENDPOINTS.UPDATE_RIFF_TOOLTIP_SEEN, 'riff_tooltip_seen');

export const updateFirstRiff = () =>
  updateProfileBoolFlag(API_ENDPOINTS.UPDATE_FIRST_RIFF, 'first_riff_created');


async function updatePreferredUsernameInDjango(preferredUsername) {
  try {
    console.log(`Updating preferred username to: ${preferredUsername}`);
    const payload = { preferred_username: preferredUsername };
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.SET_PREFERRED_USERNAME, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating preferred username:', error);
    // Check if the error response contains the expected data
    if (error.response && error.response.data) {
      // Extract and throw new error with the server's response
      throw new Error(Object.values(error.response.data).join(", "));
    } else {
      // If the response data is not in the expected format, throw a generic error
      throw new Error("An error occurred while updating your username.");
    }
  }
}



async function checkUsernameModerationTaskStatus(task_id) {
  try {
    // Note: Ensure API_ENDPOINTS.TASK_STATUS is set to the correct endpoint for checking username moderation status
    const response = await authenticatedAxiosCall('get', API_ENDPOINTS.USERNAME_TASK_STATUS, {}, {params: {task_id}});

    if (response.status !== 200) {
      console.error(`Error polling with unexpected response status: ${response.status}`);
      throw new Error(`Error polling with unexpected response status: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error in checkUsernameModerationTaskStatus:", error);
    throw new Error("Error polling task status: " + error.message);
  }
}


async function registerPushToken(pushToken) {
  console.log(`Registering push token...`);
  try {
    const payload = { token: pushToken};
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.REGISTER_PUSH_TOKEN, payload);
    console.log('Push token registered successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error registering push token:', error.response ? error.response.data : error.message);
    throw error; // Propagate the error for handling it in the calling function
  }
}


async function unregisterPushToken(pushToken) {
  console.log(`Unregistering push token...`);
  try {
    const payload = { token: pushToken };
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.UNREGISTER_PUSH_TOKEN, payload);
    console.log('Push token unregistered successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error unregistering push token:', error.response ? error.response.data : error.message);
    throw error; // Propagate the error for handling it in the calling function
  }
}


export async function fetchNotificationSettings() {
    const response = await authenticatedAxiosCall('get', API_ENDPOINTS.NOTIFICATION_SETTINGS);
    return response.data;
}

export async function updateNotificationSettings(settings) {
    const response = await authenticatedAxiosCall('patch', API_ENDPOINTS.NOTIFICATION_SETTINGS, settings);
    return response.data;
}



export {fetchProfileData, signUp, resetPassword, deactivateDjangoAccount, reactivateDjangoAccount, removeFriend, sendFriendRequest, checkIsFriend, acceptFriendRequest, rejectFriendRequest, fetchPublicProfileData, fetchNotifications, deleteNotification, updatePreferredUsernameInDjango, registerPushToken, checkUsernameModerationTaskStatus, fetchSubscriptionData, getConfig, unregisterPushToken};
