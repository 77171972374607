import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet, Image, Alert } from 'react-native';
import theme from "../../styles/theme";
import {MaterialIcons, FontAwesome, MaterialCommunityIcons} from "@expo/vector-icons";
import MiniCard from "../MiniCard";
import { useNavigation } from "@react-navigation/native";
import useCardActions from "../../hooks/useCardActions";
import {useSubscription} from "../../hooks/useSubscription";


const CardDetailsModal = ({ isVisible, onClose, card, profileData, navigateToUserProfile }) => {
  const navigation = useNavigation();
  const { isDeveloper } = useSubscription(true);

  const {
    performLike,
    performBookmark,
    performFlag,
    performSetAsProfileCard,
    performDeleteCard,
    bookmarkColor,
    shareCard
  } = useCardActions(card);

  const bookmarkIconName = bookmarkColor === theme.colors.activeBookmark ? "bookmark" : "bookmark-o";
  const bookmarkText = bookmarkColor === theme.colors.activeBookmark ? "Unbookmark Card" : "Bookmark Card";

  const navigateToDeveloperDetails = () => {
    navigation.navigate('CardDeveloperDetails', { cardUuid: card.uuid });
    onClose();
  };

  const navigateToTree = () => {
    navigation.navigate('TreeScreen', {worldUuid: card.world_uuid, cardUuid: card.uuid});
    onClose();
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}>
      <View style={styles.centeredView}>
        <TouchableOpacity
          style={StyleSheet.absoluteFill}
          activeOpacity={1}
          onPress={onClose}
        />
        <View style={styles.modalView}>
          <TouchableOpacity
            style={styles.closeButton}
            onPress={onClose}
          >
            <MaterialIcons name="close" size={24} color={theme.colors.black}/>
          </TouchableOpacity>

          <MiniCard item={card} pressable={false}/>

          <View style={styles.buttonsContainer}>
            <View style={{marginTop: theme.spacings.large}}></View>

            {/*add navigate to tree button using material community icons family tree*/}
            <TouchableOpacity style={styles.optionButton} onPress={navigateToTree}>
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons
                  name="family-tree"
                  size={20}
                  color={theme.colors.redPrimary}
                />
              </View>
              <Text style={styles.optionText}>View Tree</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.optionButton} onPress={performBookmark}>
              <View style={styles.iconContainer}>
                <FontAwesome
                  name={bookmarkIconName}
                  size={20}
                  color={theme.colors.activeBookmark}
                />
              </View>
              <Text style={styles.optionText}>{bookmarkText}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.optionButton} onPress={shareCard}>
              <View style={styles.iconContainer}>
                <MaterialIcons
                  name="ios-share"
                  size={20}
                  color={theme.colors.bluePrimary}
                />
              </View>
              <Text style={styles.optionText}>Share Card</Text>
            </TouchableOpacity>

            {card.creator_uuid === profileData?.uuid && (
              <View>
                <TouchableOpacity style={styles.optionButton} onPress={performSetAsProfileCard}>
                  <View style={styles.iconContainer}>
                    <FontAwesome name="id-card" size={20} color={theme.colors.bluePrimary}/>
                  </View>
                  <Text style={styles.optionText}>Set as Profile Card</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.optionButton, styles.optionReportButton]}
                  onPress={performDeleteCard}
                >
                  <View style={styles.iconContainer}>
                    <MaterialIcons name="delete" size={20} color={theme.colors.reportRed}/>
                  </View>
                  <Text style={[styles.optionText, styles.optionReportText]}>DELETE THIS CARD</Text>
                </TouchableOpacity>
              </View>
            )}

            <TouchableOpacity style={[styles.optionButton, styles.optionReportButton]} onPress={performFlag}>
              <View style={styles.iconContainer}>
                <FontAwesome name="flag" size={20} color={theme.colors.reportRed} />
              </View>
              <Text style={[styles.optionText, styles.optionReportText]}>REPORT THIS CARD</Text>
            </TouchableOpacity>


            {isDeveloper && (
              <TouchableOpacity style={styles.developerButton} onPress={navigateToDeveloperDetails}>
                <View style={styles.iconContainer}>
                  <FontAwesome name="code" size={20} color={theme.colors.gray}/>
                </View>
                <Text style={[styles.optionText, {color: theme.colors.gray}]}>Developer Details</Text>
              </TouchableOpacity>
            )}

          </View>

        </View>
      </View>
    </Modal>
  );
};

// Add styles here to match UpgradeModal's styling
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
  },
  modalView: {
    margin: theme.spacings.large,
    backgroundColor: theme.colors.beige,
    borderRadius: theme.borderRadius.large,
    padding: theme.spacings.large,
    alignItems: 'center',
    shadowColor: theme.colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '85%',
    maxWidth: 500,
    minHeight: '50%',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacings.small,
    right: theme.spacings.small,
    padding: 10,
  },
  cardImage: {
    width: 200, // adjust as needed
    height: 200, // adjust as needed
    borderRadius: theme.borderRadius.medium, // rounded corners
    resizeMode: 'cover',
    marginBottom: theme.spacings.medium,
  },
  cardName: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.redPrimary,
    textAlign: 'center',
    marginBottom: theme.spacings.small,
  },
  cardFlavor: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.lightgray,
    textAlign: 'center',
    marginBottom: theme.spacings.large,
  },
  buttonsContainer: {
    alignItems: 'flex-start',
  },
  optionButton: {
    borderRadius: theme.borderRadius.medium,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    alignItems: 'center',
    flexDirection: 'row',
  },
  iconContainer: {
    width: 24,
    alignItems: 'center', // Center the icon horizontally in this container
    marginRight: theme.spacings.small, // Space between icon and text
  },
  optionText: {
    paddingLeft: theme.spacings.small,
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.bluePrimary,
    textAlign: 'center',
  },
  optionReportButton: {
    marginTop: theme.spacings.xlarge,
  },
  optionReportText: {
    color: theme.colors.reportRed,
  },
  optionProfileButton: {
    marginTop: theme.spacings.xlarge,
    backgroundColor: theme.colors.bluePrimary,
    borderRadius: theme.borderRadius.medium,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    marginVertical: theme.spacings.small,
    minWidth: '70%',
  },
  optionProfileText: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.white,
    textAlign: 'center',
  },
  developerButton: {
    marginTop: theme.spacings.large,
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
  },
  developerButtonText: {
    paddingLeft: theme.spacings.small,
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.white,
    textAlign: 'center',
  },
});

export default CardDetailsModal;
