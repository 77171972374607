import React, { useEffect, useState } from 'react';
import { View, Text, TouchableWithoutFeedback, StyleSheet, Platform } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import theme from "../../styles/theme";

const CreateTooltipOverlay = ({
  isVisible,
  onClose,
  buttonRef
}) => {
  const [tooltipPosition, setTooltipPosition] = useState(0);

  useEffect(() => {
    if (buttonRef?.current && Platform.OS === 'web') {
      const measureButton = () => {
        const button = buttonRef.current;
        button.measure((x, y, width, height, pageX, pageY) => {
          // Position the tooltip 20px above the button
          setTooltipPosition(pageY - 100); // Adjust this value to control spacing
        });
      };

      measureButton();
      // Remeasure on window resize for web
      window.addEventListener('resize', measureButton);
      return () => window.removeEventListener('resize', measureButton);
    }
  }, [buttonRef]);

  if (!isVisible) return null;

  return (
    <TouchableWithoutFeedback>
      <View style={styles.overlay}>
        <View style={styles.dimmedBackground} />
        <View style={[
          styles.tooltipContent,
          Platform.OS === 'web' ? { top: tooltipPosition } : null
        ]}>
          <View style={styles.tooltipBox}>
            <Text style={styles.tooltipText}>
              Press below to{'\n'}create your story.
            </Text>
          </View>
          <FontAwesome
            name="long-arrow-down"
            size={32}
            color={theme.colors.beige}
            style={styles.tooltipArrow}
          />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1000,
  },
  dimmedBackground: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  tooltipContent: {
    position: 'absolute',
    left: 0,
    right: 0,
    alignItems: 'center',
    zIndex: 1001,
    // For mobile, we'll use bottom positioning
    ...(Platform.OS !== 'web' && {
      bottom: '18%',
    }),
  },
  tooltipBox: {
    backgroundColor: theme.colors.beige,
    padding: 5,
    paddingHorizontal: 20,
    borderRadius: theme.borderRadius.large,
    borderWidth: 0,
  },
  tooltipText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    textAlign: 'center',
    marginBottom: theme.spacings.xsmall,
    textTransform: 'uppercase'
  },
  tooltipArrow: {
  },
});

export default CreateTooltipOverlay;
