import React, {useContext, useMemo, useState} from 'react';
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Modal,
  Pressable,
  Platform,
} from 'react-native';
import { Image, ImageBackground } from 'expo-image';
import UserCircle from './UserCircle';
import theme from '../styles/theme';
import { useNavigation } from '@react-navigation/native';
import HorizontalCardStack from "./HorizontalCardStack";
import CreateButton from "./buttons/CreateButton";
import ContributorsList from './ContributorsList';
import CardCountIndicator from "./CardCountIndicator";
import {
  Ionicons,
  MaterialIcons,
  FontAwesome5,
  MaterialCommunityIcons
} from "@expo/vector-icons";
import SvgFabylHeader from "../assets/images/FabylHeader";
import {shareContent} from "../utils/share";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import {AuthContext} from "../contexts/AuthContext";
import Vorkov from "../assets/images/characters/Vorkov_medium.png";
import Frankenpug from "../assets/images/characters/Frankenpug_medium.png";
import Spacechef from "../assets/images/characters/SpaceChef_medium.png";
import Robot from "../assets/images/characters/Robot_medium.png";
import SteamPunkHillbilly from "../assets/images/characters/SteampunkHillbilly_medium.png";


const { width: width, height: SCREEN_HEIGHT } = Dimensions.get('window');
const SCREEN_WIDTH = Math.min(width, 500);
const ASPECT_RATIO = 16 / 9;
const IMAGE_HEIGHT = SCREEN_WIDTH / ASPECT_RATIO;
export const BIG_WORLD_COMPONENT_HEIGHT = Math.max(SCREEN_HEIGHT * 0.9, 700); // 90% of screen height

// Define heights for each section
const TITLE_SECTION_HEIGHT = 80; // Adjust as needed
export const BOTTOM_SECTION_HEIGHT = 90; // Adjust as needed
const KERNEL_SECTION_HEIGHT = BIG_WORLD_COMPONENT_HEIGHT - IMAGE_HEIGHT - TITLE_SECTION_HEIGHT - BOTTOM_SECTION_HEIGHT;

const ColoredImage = ({ source, color, style }) => {
  return (
    <View style={[styles.coloredImageContainer, { width: style.width, height: style.height }]}>
      <Image
        source={source}
        style={[style, { tintColor: color }]}
        contentFit="contain"
      />
    </View>
  );
};

const characters = [Vorkov, Frankenpug, Spacechef, Robot, SteamPunkHillbilly];

const BigWorldSkeletonComponent = ({ isLoading, isAuthenticated, navigateToWorldSettings }) => {
  const randomCharacter = useMemo(() => characters[Math.floor(Math.random() * characters.length)], []);
  const characterColor = theme.colors.black; // You can change this to any color you want

  return (
    <View style={[styles.skeletonContainer, {width: width}]}>
      <SvgFabylHeader style={styles.fabylHeader} fillColor={theme.colors.white} />
      {isLoading ? (
        <View style={{height: BIG_WORLD_COMPONENT_HEIGHT / 2}}>
          <ActivityIndicator size="large" color={theme.colors.white} style={styles.activityIndicator} />
        </View>
      ) : (
        <View style={styles.characterContainer}>
          <ColoredImage
            source={randomCharacter}
            color={characterColor}
            style={styles.characterImage}
          />
        </View>
      )}
      {isAuthenticated && (
        <TouchableOpacity
          style={styles.skeletonSettingsButton}
          onPress={navigateToWorldSettings}
        >
          <Ionicons name="ellipsis-horizontal" size={32} color={theme.colors.white} />
        </TouchableOpacity>
      )}
    </View>
  );
};


const BigWorldComponent = ({ world, isLoading }) => {
  const navigation = useNavigation();
  const hasCards = world?.cards && world?.cards.length > 0;
  const hasContributors = world?.contributors && world?.contributors.length > 0;
  const isLargeScreen = useIsLargeScreen()
  const { isAuthenticated } = useContext(AuthContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [lengthMore, setLengthMore] = useState(false);

  const navigateToWorldSettings = () => {
    if (world?.uuid) {
      navigation.navigate('WorldSettings', { worldUUID: world.uuid });
    }
  };

  const navigateToUserProfile = () => {
    if (world?.creator?.uuid) {
      navigation.navigate('UserProfile', { profile_uuid: world.creator.uuid });
    }
  };

  const navigateToCollaborators = () => {
    if (world?.uuid) {
      navigation.navigate('WorldCollaborators', { worldUuid: world.uuid });
    }
  };

  const handleCreateStory = () => {
    if (world) {
      navigation.navigate('ExpandedInput', { selectedWorld: world });
    }
  };

  const navigateToWorldGallery = () => {
    if (world?.uuid) {
      navigation.navigate('World', { worldUuid: world.uuid });
    }
  };

  const shareWorld = async () => {
    if (world?.uuid) {
      await shareContent('world', world.uuid);
    }
  };

  if (!world || !world?.kernel_or_summary || isLoading) {
    return <BigWorldSkeletonComponent
      isLoading={isLoading}
      isAuthenticated={isAuthenticated}
      navigateToWorldSettings={navigateToWorldSettings}
    />;
  }

  const navigateToTreeScreen = () => {
    if (world?.uuid) {
      navigation.navigate('TreeScreen', {worldUuid: world.uuid});
    }
  };

  return (
    <View style={[styles.container, { height: BIG_WORLD_COMPONENT_HEIGHT }]}>
      <ImageBackground source={{uri: world.art}} style={styles.topSectionOuter} imageStyle={{ opacity: 0.15 }}>
      <View style={[styles.topSection]}>
        <View style={styles.imageContainer}>
          {world.art ? (
            <Image source={{uri: world.art}} style={[styles.image]}/>
          ) : (
            hasCards && (
              <HorizontalCardStack
                cards={world.cards}
                width={SCREEN_WIDTH}
                height={IMAGE_HEIGHT}
              />
            )
          )}
        </View>

        <View style={styles.titleContainer}>
          <View style={styles.titleWrapper}>
            <Text style={styles.name} numberOfLines={2}>{world.name}</Text>
          </View>
          {isAuthenticated && (
          <TouchableOpacity
            style={styles.settingsButton}
            onPress={navigateToWorldSettings}
          >
            <Ionicons name="ellipsis-horizontal" size={32} color={theme.colors.white} />
          </TouchableOpacity>
          )}
        </View>

        <TouchableOpacity
          style={styles.kernelContainer}
          contentContainerStyle={styles.kernelContentContainer}
          onPress={() => setModalVisible(true)}
        >
          <Text
            style={styles.kernel}
            numberOfLines={7}
            onTextLayout={e => {
              const {lines} = e.nativeEvent;
              setLengthMore(lines.length >= 7);
            }}
          >
            {world.kernel_or_summary}
          </Text>
          {(lengthMore || Platform.OS === 'web') && (
            <TouchableOpacity
              onPress={() => setModalVisible(true)}
              hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
            >
              <Text style={styles.readMore}>READ MORE</Text>
            </TouchableOpacity>
          )}
        </TouchableOpacity>

        <View style={[
          styles.collaboratorsSection,
        ]}>
          <View style={styles.collaboratorsContent}>
            {world.creator && (
              <TouchableOpacity onPress={navigateToUserProfile}>
                <Text style={styles.contributorTitle}>CREATOR</Text>
                <View style={styles.creatorContainer}>
                  <UserCircle profile_uuid={world.creator.uuid} size={40} borderColor={theme.colors.beige}/>
                  <Text style={styles.creatorName}>
                    {world.creator.preferred_username}
                  </Text>
                </View>
              </TouchableOpacity>
            )}

            {hasContributors && (
              <TouchableOpacity onPress={navigateToCollaborators}>
                <Text style={styles.contributorTitle}>CONTRIBUTORS</Text>
                <View style={styles.contributorsContainer}>
                  <ContributorsList
                    contributors={world.contributors}
                    additional_contributors_count={world.additional_contributors_count}
                    size={35}
                  />
                </View>
              </TouchableOpacity>
            )}
          </View>
          <View style={{flexDirection: "column", alignSelf: "flex-end"}}>
            <TouchableOpacity
              style={styles.share}
              onPress={shareWorld}
            >
              <MaterialIcons
                name="ios-share"
                size={34}
                color={theme.colors.lighterBlue}
              />
            </TouchableOpacity>

            {/*<TouchableOpacity*/}
            {/*  style={styles.treeButton} // New styles for the tree button*/}
            {/*  onPress={navigateToTreeScreen} // Function to navigate*/}
            {/*>*/}
            {/*  /!*<FontAwesome5 name="tree" size={37} color={theme.colors.bluePrimary}/>*!/*/}
            {/*  <MaterialCommunityIcons name="family-tree" size={37} color={theme.colors.bluePrimary}/>*/}
            {/*</TouchableOpacity>*/}

            <TouchableOpacity
              style={styles.cardCountContainer}
              // onPress={navigateToWorldGallery}
              onPress={navigateToTreeScreen}
            >
              <Text style={styles.counterText}>VIEW TREE</Text>
              <View style={{flexDirection: "row", justifyContent: "space-around", width: "100%"}}>
                {/*<Ionicons name={"grid-outline"} size={37} color={theme.colors.redPrimary} />*/}
                <MaterialCommunityIcons name="family-tree" size={36} color={theme.colors.redPrimary}/>
                <CardCountIndicator count={world.total_cards || "0"} size="medium" />
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <View style={styles.bottomSection}>
        <CreateButton
          onPress={handleCreateStory}
          text="CREATE IN THIS WORLD..."
        />
      </View>
      </ImageBackground>


      {modalVisible && (
        <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.modalContainer}>

            <Pressable style={styles.backdrop} onPress={() => setModalVisible(false)} />

            <View style={styles.modalContent}>

              <View style={styles.modalNameContainer}>
                <Text style={styles.name}>{world.name}</Text>
              </View>
              <ScrollView>
                <Text style={styles.modalText}>
                  {world.kernel_or_summary}
                </Text>
              </ScrollView>
              <TouchableOpacity
                onPress={() => setModalVisible(false)}
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
              >
                <Text style={styles.closeButton}>CLOSE</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}

    </View>
  );
};

const styles = StyleSheet.create({
  borderTopRadius: {
    borderTopRightRadius: theme.borderRadius.medium,
    borderTopLeftRadius: theme.borderRadius.medium,
  },
  borderBottomRadius: {
    borderBottomRightRadius: theme.borderRadius.medium,
    borderBottomLeftRadius: theme.borderRadius.medium,
  },
  container: {
    backgroundColor: theme.colors.beige,
    alignSelf: 'center',
    maxWidth: SCREEN_WIDTH,
  },
  imageContainer: {
    height: IMAGE_HEIGHT,
  },
  image: {
    width: SCREEN_WIDTH,
    height: IMAGE_HEIGHT,
    resizeMode: 'cover',
    backgroundColor: theme.colors.gray,
  },
  titleContainer: {
    height: TITLE_SECTION_HEIGHT,
    maxWidth: SCREEN_WIDTH,
    paddingHorizontal: theme.spacings.medium,
    backgroundColor: theme.colors.gray,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'space-between',
  },
  name: {
    color: theme.colors.yellowPrimary,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    textTransform: "uppercase",
    // flex: 1,
  },
  kernelContentContainer: {
    padding: theme.spacings.medium,
  },
  creatorName: {
    color: theme.colors.lighterBlue,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.large,
    marginLeft: theme.spacings.small,
  },
  topSectionOuter: {
    backgroundColor: theme.colors.gray,
    width: width,
    alignSelf: 'center',
    alignItems: 'center',
  },
  topSection: {
    backgroundColor: theme.colors.gray,
    height: BIG_WORLD_COMPONENT_HEIGHT - BOTTOM_SECTION_HEIGHT,
    width: SCREEN_WIDTH,
  },
  bottomSection: {
    height: BOTTOM_SECTION_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: theme.colors.beige,
    backgroundColor: theme.colors.gray,
    width: SCREEN_WIDTH,
  },

  collaboratorsSection: {
    backgroundColor: theme.colors.gray,
    padding: theme.spacings.medium,
    flexDirection: 'row',  // Add this
    justifyContent: 'space-between',  // Add this
    alignItems: "flex-end",
  },
  collaboratorsContent: {
    // flex: 1,  // Add this
  },
  cardCountContainer: {
    // justifyContent: 'flex-end',  // Add this
    // alignItems: "flex-end",
    justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    width: 80,
  },
  treeButton: {
    alignSelf: "flex-end",
    marginBottom: theme.spacings.small,
    marginRight: theme.spacings.small,
  },
  contributorTitle: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.medium,
    color: theme.colors.lighterBlue,
    textDecorationLine: "underline",
    marginBottom: theme.spacings.xsmall,  // Add this for spacing
  },
  creatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacings.small,  // Add this for spacing
  },
  contributorsContainer: {
    flexDirection: 'row',
  },
  counterText: {
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.small,
    marginLeft: theme.spacings.small,
  },
  settingsButton: {
    position: 'absolute',
    right: theme.spacings.medium,
    padding: theme.spacings.small,
  },
  share: {
    alignSelf: "flex-end",
    marginBottom: theme.spacings.large,  // Add this for spacing
    marginRight: theme.spacings.small,
  },
  skeletonContainer: {
    height: BIG_WORLD_COMPONENT_HEIGHT,
    width: SCREEN_WIDTH,
    alignSelf: 'center',
    backgroundColor: theme.colors.gray,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.spacings.xxlarge,
  },
  fabylHeader: {
    marginTop: theme.spacings.xxlarge,
  },
  activityIndicator: {
    marginTop: theme.spacings.large,
  },
  characterContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  characterImage: {
    width: 200,
    height: 300,
    resizeMode: 'contain',
  },
  coloredImageContainer: {
    overflow: 'hidden',
  },
  kernelContainer: {
    // height: KERNEL_SECTION_HEIGHT,
    flex: 1,
    maxWidth: SCREEN_WIDTH,
    // backgroundColor: theme.colors.transparent_white,
    // borderTopWidth: StyleSheet.hairlineWidth,
    // borderBottomWidth: StyleSheet.hairlineWidth,
    // borderColor: theme.colors.yellowPrimary,
    paddingHorizontal: theme.spacings.medium,
    paddingVertical: theme.spacings.small,
  },
  kernel: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.white,
  },
  readMore: {
    textAlign: 'right',
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.smallish,
    paddingRight: theme.spacings.medium,
    fontFamily: theme.fonts.family.regular,
    textDecorationLine: 'underline',
    marginTop: theme.spacings.small,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContent: {
    backgroundColor: theme.colors.gray,
    padding: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.yellowPrimary,
    maxWidth: SCREEN_WIDTH - theme.spacings.large,
    // maxHeight: SCREEN_HEIGHT - theme.spacings.large * 4,
    maxHeight: "75%"
  },
  modalText: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.white,
  },
  closeButton: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    textDecorationLine: 'underline',
    marginTop: theme.spacings.small,
    alignSelf: 'center',
  },
  modalNameContainer: {
    marginBottom: theme.spacings.medium,
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  titleWrapper: {
    flex: 1,
    marginRight: theme.spacings.xxlarge, // Adjust this value based on the width of your settings button
  },
  skeletonSettingsButton: {
    position: 'absolute',
    bottom: theme.spacings.medium,
    right: theme.spacings.medium,
    padding: theme.spacings.small,
  },
});

export default BigWorldComponent;
