import API_ENDPOINTS from "../../apiEndpoints";
import { authenticatedAxiosCall } from "./tokenService";
import axios from "axios";
import alert from "../alert";

async function fetchCards(url) {
  try {
    const response = await authenticatedAxiosCall('get', url);

    // Assuming the API response has a 'results' and 'next' structure
    return {
      cards: response.data.results,
      nextPageURL: response.data.next
    };
  } catch (error) {
    console.log(`Error fetching cards from url: ${url}`)
    console.error("Error fetching cards:", error);

    // In case of an error, return an empty array for cards and null for nextPageURL
    return {
      cards: [],
      nextPageURL: null
    };
  }
}

async function fetchWorlds(url) {
  try {
    const response = await authenticatedAxiosCall('get', url);

    // Assuming the API response for worlds also has a 'results' and 'next' structure
    return {
      worlds: response.data.results,
      nextPageURL: response.data.next
    };
  } catch (error) {
    console.log(`Error fetching worlds from url: ${url}`)
    console.error("Error fetching worlds:", error);

    // In case of an error, return an empty array for worlds and null for nextPageURL
    return {
      worlds: [],
      nextPageURL: null
    };
  }
}


async function fetchWorld(worldUUID) {
  try {
    const worldUrl = API_ENDPOINTS.generate.WORLD_DETAIL(worldUUID);
    const response = await authenticatedAxiosCall('get', worldUrl);

    return {
      world: response.data,
      error: null,
    };
  } catch (error) {
    console.error("Error fetching world:", error);
    return {
      world: null,
      error: error.response ? error.response.data : "An unknown error occurred",
    };
  }
}


export async function fetchWorldTree(worldUUID) {
  try {
    const worldTreeUrl = API_ENDPOINTS.generate.WORLD_TREE(worldUUID); // Generate the tree endpoint URL
    const response = await authenticatedAxiosCall('get', worldTreeUrl);

    return {
      tree: response.data, // The tree structure from the API
      error: null,
    };
  } catch (error) {
    console.error("Error fetching world tree:", error);
    return {
      tree: null,
      error: error.response ? error.response.data : "An unknown error occurred",
    };
  }
}


export const fetchWorldOwner = async (worldUUID) => {
  const url = API_ENDPOINTS.generate.WORLD_OWNER(worldUUID);
  const response = await authenticatedAxiosCall('get', url);
  return response.data;
};



export const updateWorldVisibility = async (worldUUID, visibility, friends = []) => {
  const url = API_ENDPOINTS.generate.UPDATE_WORLD_VISIBILITY(worldUUID);
  const response = await authenticatedAxiosCall('patch', url, { visibility, friends });
  return response.data;
};


export const updateWorldContribution = async (worldUUID, contributionSetting, contributors = []) => {
  const url = API_ENDPOINTS.generate.UPDATE_WORLD_CONTRIBUTION(worldUUID);
  const response = await authenticatedAxiosCall('patch', url, { contribution_setting: contributionSetting, contributors });
  return response.data;
};


export const updateWorldSettings = async (worldUUID, visibility, contributionSetting, friends = [], contributors = []) => {
  const visibilityUrl = API_ENDPOINTS.generate.UPDATE_WORLD_VISIBILITY(worldUUID);
  const contributionUrl = API_ENDPOINTS.generate.UPDATE_WORLD_CONTRIBUTION(worldUUID);

  const visibilityResponse = await authenticatedAxiosCall('patch', visibilityUrl, { visibility, friends });
  const contributionResponse = await authenticatedAxiosCall('patch', contributionUrl, { contribution_setting: contributionSetting, contributors });

  return {
    visibility: visibilityResponse.data,
    contribution: contributionResponse.data,
  };
};


async function fetchData(endpoint) {
  const response = await authenticatedAxiosCall('get', endpoint);
  return response.data;
}


function fetchCardByUUID(uuid) {
  try {
    return fetchData(API_ENDPOINTS.generate.CARD_DETAIL(uuid));
  }
  catch (error) {
    console.error("Error fetching card by UUID:", error);
    throw new Error("Error fetching card by UUID.");
  }
}


export function fetchMinimalCardByUUID(uuid) {
  console.log(`trying endpoint: ${API_ENDPOINTS.generate.MINIMAL_CARD(uuid)}`)
  try {
    return fetchData(API_ENDPOINTS.generate.MINIMAL_CARD(uuid));
  }
  catch (error) {
    console.error("Error fetching minimal card by UUID:", error);
    throw new Error("Error fetching minimal card by UUID.");
  }
}

export function fetchCardDeveloperDetails(uuid) {
  return fetchData(API_ENDPOINTS.generate.CARD_DEVELOPER_DETAIL(uuid));
}

export function fetchCardFriendStatus(uuid) {
  try {
    return fetchData(API_ENDPOINTS.generate.CARD_FRIEND_STATUS(uuid));
  }
  catch (error) {
    console.error("Error fetching card friend status:", error);
    throw new Error("Error fetching card friend status.");
  }
}

export async function fetchCanRiffStatus(uuid) {
  const endpoint = API_ENDPOINTS.generate.CHECK_CAN_RIFF(uuid);
  try {
    const response = await authenticatedAxiosCall('get', endpoint);
    return response.data;
  } catch (error) {
    console.error("Error checking if can riff:", error);
    throw new Error("Error checking if can riff.");
  }
}

export const toggleFavorite = async (uuid) => {
  const endpoint = API_ENDPOINTS.generate.TOGGLE_FAVORITE(uuid);
  const response = await authenticatedAxiosCall('post', endpoint, {})
  return response.data;
};



export const toggleBookmark = async (uuid) => {
  const endpoint = API_ENDPOINTS.generate.TOGGLE_BOOKMARK(uuid);
  const response = await authenticatedAxiosCall('post', endpoint, {})
  return response.data;
};


async function createCard(inputValue, cardRiffedFrom = null, userSendingTo = null, worldUuid = null, style = null) {
  if (!inputValue.trim()) {
    throw new Error("Name cannot be empty!");
  }

  const requestData = {
    name: inputValue,
    ...(cardRiffedFrom && { card_riffed_from: cardRiffedFrom }),
    ...(userSendingTo && { user_sending_to: userSendingTo }),
    ...(worldUuid && { world_uuid: worldUuid }),
    ...(style && { style }),  // Add the style parameter
  };

  try {
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.CREATE_CARD, requestData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.code) {
      let errorMessage;
      switch (error.response.data.code) {
        case 'NOT_FRIENDS_WITH_A_CONTRIBUTOR':
          errorMessage = error.response.data.detail || "You are not friends with a contributor.";
          break;
        case 'INSUFFICIENT_GENERATIONS':
          errorMessage = "You don't have enough generations remaining to create a card.";
          break;
        case 'INSUFFICIENT_WORLD_GENERATIONS':
          errorMessage = "This world does not have any generations remaining.";
          break;
        default:
          errorMessage = error.response.data.detail || "Failed to initiate card creation.";
      }
      console.error("Error code:", error.response.data.code);
      const new_error = new Error(errorMessage);
      new_error.code = error.response.data.code;
      throw error;
    } else {
      throw new Error("Failed to initiate card creation.");
    }
  }
}

async function checkTaskData(task_id) {
  try {
    const response = await authenticatedAxiosCall('get', API_ENDPOINTS.TASK_STATUS, {}, {params: {task_id}})

    if (response.status !== 200) {
      throw new Error(`Error polling with unexpected response status: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    // Handle error here (e.g., log the error, alert the user)
    // throw new Error("Error polling task status: " + error.message);
    console.error("Error in checkTaskData:", error);
    throw new Error("Error polling task status: " + error.message);
  }
}


export const toggleFlag = async (uuid) => {
  const endpoint = API_ENDPOINTS.generate.TOGGLE_FLAG(uuid);
  try {
    const response = await authenticatedAxiosCall('post', endpoint, {});
    return response.data;
  } catch (error) {
    console.error("Error flagging card:", error);
    throw new Error("Error flagging card.");
  }
};


async function setAsProfileCard(cardUuid) {
  const endpoint = API_ENDPOINTS.generate.SET_PROFILE_CARD(cardUuid);
  try {
    const response = await authenticatedAxiosCall('post', endpoint);
    return response.data;
  } catch (error) {
    console.error("Error setting card as profile card:", error);
    throw new Error("Error setting card as profile card.");
  }
}

async function deleteCard(cardUuid) {
  const endpoint = API_ENDPOINTS.generate.DELETE_CARD(cardUuid);
  try {
    const response = await authenticatedAxiosCall('delete', endpoint);
    // Axios automatically throws an error for bad status codes, so if we're here, the request was successful.
    // Instead of returning response.data, return a status object or a simple success message since there's no content.
    if (response.status === 204) {
      // Explicitly handle 204 No Content
      return { success: true };
    } else {
      // Handle other 2xx responses if you expect any.
      return response.data;
    }
  } catch (error) {
    console.error("Error deleting card:", error);
    throw new Error("Error deleting card.");
  }
}



const fetchWorldBatch = async () => {
  try {
    const endpoint = API_ENDPOINTS.WORLD_BATCHES;
    const response = await axios.get(endpoint);
    return response.data;
  }
  catch (error) {
    console.error("Error fetching world batch:", error);
    throw new Error("Error fetching world batch.");
  }
};


export const fetchNextWorld = async (currentWorldUUID, direction = 'next') => {
  try {
    const endpoint = API_ENDPOINTS.generate.WORLD_NAVIGATION(currentWorldUUID, direction);
    console.log(`Fetching next world from endpoint: ${endpoint}`)
    const response = await authenticatedAxiosCall('get', endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching next world:', error);
    throw error;
  }
};


export async function createWorld(name, kernel) {
  try {
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.CREATE_WORLD, { name, kernel });
    return response.data;
  } catch (error) {
    console.error("Error creating world:", error);
    throw new Error("Failed to create world.");
  }
}

export async function updateWorld(uuid, name, kernel) {
  try {
    const response = await authenticatedAxiosCall('patch', API_ENDPOINTS.generate.UPDATE_WORLD(uuid), { name, kernel });
    return response.data;
  } catch (error) {
    console.error("Error updating world:", error);
    throw new Error("Failed to update world.");
  }
}


export async function fetchUserCreatedWorlds() {
  try {
    const response = await authenticatedAxiosCall('get', API_ENDPOINTS.USER_CREATED_WORLDS);
    return response.data;
  } catch (error) {
    console.error("Error fetching user created worlds:", error);
    throw new Error("Failed to fetch user created worlds.");
  }
}


export const fetchRecentFriends = async () => {
  try {
    const response = await authenticatedAxiosCall("get", API_ENDPOINTS.RECENT_FRIENDS);
    return response.data.results;
  } catch (error) {
    console.error('Error fetching recent friends:', error);
    throw error;
  }
};

export const fetchRecentWorlds = async () => {
  try {
    const response = await authenticatedAxiosCall("get", API_ENDPOINTS.RECENT_WORLDS);
    return response.data.results;
  } catch (error) {
    console.error('Error fetching recent worlds:', error);
    throw error;
  }
};


export async function fetchFeaturedWorld() {
  try {
    const response = await authenticatedAxiosCall('get', API_ENDPOINTS.FEATURED_WORLD);
    return response.data;
  } catch (error) {
    console.error("Error fetching featured world:", error);
    throw new Error("Failed to fetch featured world.");
  }
}


export async function fetchLandingPageFeaturedWorlds(limit = 12) {
  try {
    const response = await authenticatedAxiosCall('get', API_ENDPOINTS.LANDING_PAGE_FEATURED_WORLDS, {
      params: { limit }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching landing page featured worlds:", error);
    throw new Error("Failed to fetch landing page featured worlds.");
  }
}


export function fetchCardAudio(uuid) {
  try {
    return fetchData(API_ENDPOINTS.generate.CARD_AUDIO(uuid));
  }
  catch (error) {
    console.error("Error fetching card audio:", error);
    throw new Error("Error fetching card audio.");
  }
}


export async function fetchCardStyles() {
  try {
    const response = await authenticatedAxiosCall('get', API_ENDPOINTS.CARD_STYLES);
    return response.data;
  } catch (error) {
    console.error("Error fetching card styles:", error);
    throw new Error("Failed to fetch card styles.");
  }
}


export {checkTaskData};
export {createCard};
export {fetchCardByUUID};
export {fetchCards};
export {fetchWorlds};
export {fetchWorld};
export {fetchWorldBatch};
export { setAsProfileCard };
export { deleteCard };
