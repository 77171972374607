import { scale, verticalScale, moderateScale, moderateVerticalScale } from 'react-native-size-matters';
import { Dimensions } from 'react-native';


const { width } = Dimensions.get('window');

// Custom scaling function
export const customScale = (size) => {
  if (width < 360) {
    // Smaller devices
    return scale(size) * 0.8; // Scale down a bit more
  } else if (width >= 360 && width < 768) {
    // Medium devices
    return moderateVerticalScale(size, 0.3); // Use moderate scaling with a custom factor
  } else {
    // Larger devices
    return moderateVerticalScale(size, 0.3); // Scale up a bit more
  }
};

// Updated helper functions for scaling
const scaleSize = (size) => scale(size);
const verticalScaleSize = (size) => verticalScale(size);
export const moderateScaleSize = (size, factor = 0.3) => moderateVerticalScale(size, factor);  // Adjusted for general use
// export const cardScaleSize = (size) => moderateVerticalScale(size, 0.3);  // Adjusted for card components
export const cardScaleSize = (size) => customScale(size);  // Adjusted for card components
export const textScaleSize = (size) => moderateScale(size, 0.3);  // Adjusted for text components


// Base sizes
const baseSizes = {
  fontSizes: {
    xsmall: 10,
    small: 14,
    smallish: 15,
    medium: 16,
    large: 20,
    xlarge: 24,
    xxlarge: 30,
    banner: 50,
  },
  spacings: {
    xxsmall: 2,
    xsmall: 4,
    small: 8,
    medium: 16,
    large: 24,
    xlarge: 32,
    xxlarge: 40,
  },
  borderRadius: {
    small: 4,
    medium: 8,
    large: 16,
    xlarge: 32,
  },
};

// Automatically scale base sizes
const scaledSizes = Object.keys(baseSizes).reduce((acc, key) => {
  acc[key] = Object.keys(baseSizes[key]).reduce((innerAcc, innerKey) => {
    const value = baseSizes[key][innerKey];
    // Use moderateScale for font sizes for a balanced scaling
    innerAcc[innerKey] = key === 'fontSizes' ? textScaleSize(value) : scaleSize(value);
    return innerAcc;
  }, {});
  return acc;
}, {});


export const theme = {
  colors: {
    beige: '#F7EBDF',
    transparent_beige: 'rgba(247,235,223, 1.0)',
    transparent_white: 'rgba(255,255,255, 0.1)',
    gray: '#333333',
    slightly_transparent_gray: 'rgba(51, 51, 51, 0.90)',
    partial_transparent_gray: 'rgba(51, 51, 51, 0.50)',
    transparent_gray: 'rgba(51, 51, 51, 0.05)',
    lightgray: '#6E6E6E',
    verylightgray: '#E0E0E0',
    lighterBlue: '#679AFF',
    bluePrimary: '#3366CC',
    blueSecondary: '#22406B',
    redPrimary: '#DB4C2A',
    redPrimaryPlaceholder: 'rgba(219, 76, 42, 0.6)',
    redSecondary: '#661212',
    yellowPrimary: '#FFC523',
    yellowSecondary: '#896500',
    white: '#FFFFFF',
    black: '#000000',
    reportRed: '#ff0033',
    loadingBackground: "#FEF7F2", // Lightened version of #F7EBDF
    loadingForeground: "#EAD9C9", // A darker shade of beige for contrast
    activeHeart: '#E57A70',
    // activeBookmark: '#FFD700', // Example: Gold
    activeBookmark: '#FFC523', // Example: Gold
  },
  fonts: {
    family: {
      xlight: "Barlow-ExtraLight",
      light: "Barlow-Light",
      regular: "Barlow-Regular",
      black: "Barlow-Black",
      medium: "Barlow-Medium",
      semibold: "Barlow-SemiBold",
      bold: "Barlow-Bold",
      xbold: "Barlow-ExtraBold",
      // Add other font families as needed
      italic: "Barlow-Italic",
      monospace: 'Courier New',
    },
    sizes: baseSizes.fontSizes,
    scaledSizes: scaledSizes.fontSizes,
    weight: {
      light: '200',
      normal: '400',
      bold: '700',
      xbold: '800',
      // Add other weights as needed
    },
  },
  spacings: baseSizes.spacings,
  scaledSpacings: scaledSizes.spacings,
  borderRadius: baseSizes.borderRadius,
  scaledBorderRadius: scaledSizes.borderRadius,
  layout: {
    drawerWidth: 230,
  },
  breakpoints: {
    small: 320,
    smallish: 450,
    medium: 768,
    large: 1024,
  }
};

export default theme;
