// hooks/useCardDeveloperDetails.js
import { useQuery } from 'react-query';
import { fetchCardDeveloperDetails } from '../utils/api/cardsService';

export const useCardDeveloperDetails = (cardUuid) => {
  const { data, isLoading, isError } = useQuery(
    ['cardDeveloperDetails', cardUuid],
    () => fetchCardDeveloperDetails(cardUuid),
    {
      enabled: !!cardUuid,
    }
  );

  return {
    developerDetails: data,
    isLoading,
    isError
  };
};
