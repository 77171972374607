// apiEndpoints.js
import Constants from "expo-constants";

const BASE_URL = process.env.EXPO_PUBLIC_API_URL

const STATIC_ENDPOINTS = {
  CONFIG: `${BASE_URL}/cards/api/config/`,
  // FOLLOWING_CARDS: `${BASE_URL}/cards/api/following_cards/`,
  FRIENDS_CARDS: `${BASE_URL}/cards/api/friends_cards/`,
  // FOLLOWING_WORLDS: `${BASE_URL}/cards/api/following_worlds/`,
  FRIENDS_WORLDS: `${BASE_URL}/cards/api/friends_worlds/`,
  // BIG_FOLLOWING_WORLDS: `${BASE_URL}/cards/api/big_following_worlds/`,
  BIG_FRIENDS_WORLDS: `${BASE_URL}/cards/api/big_friends_worlds/`,
  USER_WORLDS: `${BASE_URL}/cards/api/user_worlds/`,
  USER_BIG_WORLDS: `${BASE_URL}/cards/api/user_big_worlds/`,
  ALL_BIG_WORLDS: `${BASE_URL}/cards/api/all_big_worlds/`,
  WORLD_BATCHES: `${BASE_URL}/cards/api/world_batches/`,

  // World Kernels
  CREATE_WORLD: `${BASE_URL}/cards/api/worlds/create-world/`,
  USER_CREATED_WORLDS: `${BASE_URL}/cards/api/worlds/created/`,

  RECENT_FRIENDS: `${BASE_URL}/cards/api/recent-friends/`,
  RECENT_WORLDS: `${BASE_URL}/cards/api/recent-worlds/`,
  FEATURED_WORLD: `${BASE_URL}/cards/api/featured-world/`,
  LANDING_PAGE_FEATURED_WORLDS: `${BASE_URL}/cards/api/featured-worlds/landing-page/`,

  PROFILE: `${BASE_URL}/cards/api/profile/`,
  SUBSCRIPTION: `${BASE_URL}/cards/api/subscription/`,
  PROFILE_CARDS: `${BASE_URL}/cards/api/own_profile_cards/`,
  USER_BOOKMARKED_CARDS: `${BASE_URL}/cards/api/bookmarked_cards/`,
  ALL_CARDS: `${BASE_URL}/cards/api/all_cards/`,
  RANDOM_CARDS: `${BASE_URL}/cards/api/random_cards/`,
  CREATE_CARD: `${BASE_URL}/cards/api/cards/create/`,
  TASK_STATUS: `${BASE_URL}/cards/api/cards/tasks/`,
  USERNAME_TASK_STATUS: `${BASE_URL}/cards/api/profile/get-username-task-status/`,
  SEARCH: `${BASE_URL}/cards/api/search/`,

  ALL_USERS_SEARCH: `${BASE_URL}/cards/api/all-users-search/`,
  // FOLLOWERS_SEARCH: `${BASE_URL}/cards/api/followers-search/`,
  // FOLLOWING_SEARCH: `${BASE_URL}/cards/api/following-search/`,
  FRIENDS_SEARCH: `${BASE_URL}/cards/api/friends-search/`,
  BLOCKED_USERS_SEARCH: `${BASE_URL}/cards/api/blocked-users-search/`,

  ALL_CARDS_SEARCH: `${BASE_URL}/cards/api/all-cards-search/`,
  USER_CARDS_SEARCH: `${BASE_URL}/cards/api/user-cards-search/`,
  // FOLLOWING_CARDS_SEARCH: `${BASE_URL}/cards/api/following-cards-search/`,
  FRIENDS_CARDS_SEARCH: `${BASE_URL}/cards/api/friends-cards-search/`,

  // AUTH
  TOKEN: `${BASE_URL}/api/token/`,
  REFRESH_TOKEN: `${BASE_URL}/api/token/refresh/`,
  BLACKLIST_TOKEN: `${BASE_URL}/api/token/blacklist/`,
  PASSWORD_RESET: `${BASE_URL}/dj-rest-auth/password/reset/`,
  PASSWORD_RESET_CONFIRM: `${BASE_URL}/dj-rest-auth/password/reset/confirm/`,
  PASSWORD_CHANGE: `${BASE_URL}/dj-rest-auth/password/change/`,
  EMAIL_CHANGE: `${BASE_URL}/accounts/email/change/`,
  SIGNUP: `${BASE_URL}/dj-rest-auth/registration/`,

  // USER
  DEACTIVATE: `${BASE_URL}/cards/deactivate/`,
  REACTIVATE: `${BASE_URL}/cards/reactivate_account/`,
  NOTIFICATIONS: `${BASE_URL}/cards/api/notifications/`,
  READ_NOTIFICATIONS: `${BASE_URL}/cards/api/notifications/read/`,
  UNREAD_NOTIFICATIONS: `${BASE_URL}/cards/api/notifications/unread/`,
  MARK_ALL_READ: `${BASE_URL}/cards/api/notifications/mark-all-as-read/`,
  UNREAD_NOTIFICATION_COUNT: `${BASE_URL}/cards/api/notifications/unread-count/`,
  NOTIFICATION_SETTINGS: `${BASE_URL}/cards/api/settings/notifications/`,
  SET_PREFERRED_USERNAME: `${BASE_URL}/cards/api/profile/set-preferred-username/`,
  REGISTER_PUSH_TOKEN: `${BASE_URL}/cards/api/push-token/register/`,
  UNREGISTER_PUSH_TOKEN: `${BASE_URL}/cards/api/push-token/unregister/`,
  //// Onboarding
  UPDATE_TUTORIAL_SEEN: `${BASE_URL}/cards/api/profile/tutorial-seen/`,
  UPDATE_DISCLAIMER_ACKNOWLEDGED: `${BASE_URL}/cards/api/profile/disclaimer-acknowledged/`,
  UPDATE_CREATE_TOOLTIP_SEEN: `${BASE_URL}/cards/api/profile/create-tooltip-seen/`,
  UPDATE_FIRST_CARD: `${BASE_URL}/cards/api/profile/first-card/`,
  UPDATE_RIFF_TOOLTIP_SEEN: `${BASE_URL}/cards/api/profile/riff-tooltip-seen/`,
  UPDATE_FIRST_RIFF: `${BASE_URL}/cards/api/profile/first-riff/`,
  UPDATE_RIFF_CREATED_TOOLTIP_SEEN: `${BASE_URL}/cards/api/profile/riff-created-tooltip-seen/`,

  // STRIPE
  CANCEL_SUBSCRIPTION: `${BASE_URL}/cards/cancel/`, // Update with the correct path
  REACTIVATE_SUBSCRIPTION: `${BASE_URL}/cards/reactivate/`, // Update with the correct path
  CREATE_CUSTOMER_PORTAL: `${BASE_URL}/cards/create-customer-portal-session/`,
  CHECKOUT_SESSION: `${BASE_URL}/cards/create-checkout-session/`,
  PREVIEW_SUBSCRIPTION_CHANGE: `${BASE_URL}/cards/preview-subscription-change/`,
  CONFIRM_SUBSCRIPTION_CHANGE: `${BASE_URL}/cards/confirm-subscription-change/`,
  STRIPE_PRODUCT_PRICES: `${BASE_URL}/cards/stripe/products/prices/`,

  // FRIEND REQUESTS
  FRIEND_REQUESTS: `${BASE_URL}/cards/api/friend_requests/`,
  FRIEND_REQUESTS_COUNT: `${BASE_URL}/cards/api/friend_requests/count/`,

  SUGGESTED_TITLES: `${BASE_URL}/cards/api/suggested-titles/`,

  // styles
  CARD_STYLES: `${BASE_URL}/cards/api/card-styles/`,
};

const DYNAMIC_ENDPOINTS = {
  CARD_DETAIL: (uuid) => `${BASE_URL}/cards/api/cards/${uuid}/`,
  CARD_DEVELOPER_DETAIL: (uuid) => `${BASE_URL}/cards/api/cards/${uuid}/developer/`,
  MINIMAL_CARD: (uuid) => `${BASE_URL}/cards/api/cards/minimal-card/${uuid}/`,
  CHECK_CAN_RIFF: (uuid) => `${BASE_URL}/cards/api/cards/can-riff/${uuid}/`,
  CARD_FRIEND_STATUS: (uuid) => `${BASE_URL}/cards/api/cards/${uuid}/friend_status/`,
  WORLD_DETAIL: (uuid) => `${BASE_URL}/cards/api/worlds/${uuid}/`,
  WORLD_TREE: (uuid) => `${BASE_URL}/cards/api/worlds/${uuid}/tree/`,
  UPDATE_WORLD: (uuid) => `${BASE_URL}/cards/api/worlds/update-world/${uuid}/`,
  UPDATE_WORLD_VISIBILITY: (uuid) =>  `${BASE_URL}/cards/api/worlds/${uuid}/visibility/`,
  UPDATE_WORLD_CONTRIBUTION: (uuid) => `${BASE_URL}/cards/api/worlds/${uuid}/contribution/`,
  WORLD_OWNER: (uuid) => `${BASE_URL}/cards/api/worlds/${uuid}/owner/`,
  TOGGLE_FAVORITE: (uuid) => `${BASE_URL}/cards/api/cards/${uuid}/vote/`,
  TOGGLE_BOOKMARK: (uuid) => `${BASE_URL}/cards/api/cards/${uuid}/toggle-bookmark/`,
  CARD_SIBLINGS: (uuid) => `${BASE_URL}/cards/api/cards/${uuid}/siblings/`,
  CARD_LINEAGE: (uuid) => `${BASE_URL}/cards/api/cards/${uuid}/lineage/`,
  USER_PROFILE_CARDS: (profileUuid) => `${BASE_URL}/cards/api/profile_cards/${profileUuid}`,
  // FOLLOW_USER: (profileUuid) => `${BASE_URL}/cards/api/cards/${profileUuid}/follow/`,
  // UNFOLLOW_USER: (profileUuid) => `${BASE_URL}/cards/api/cards/${profileUuid}/unfollow/`,
  // IS_FOLLOWING_USER: (profileUuid) => `${BASE_URL}/cards/api/cards/${profileUuid}/is_following/`,
  PUBLIC_PROFILE: (profileUuid) => `${BASE_URL}/cards/api/public-profile/${profileUuid}/`,
  MINIMAL_PROFILE: (profileUuid) => `${BASE_URL}/cards/api/minimal-profile/${profileUuid}/`,
  DELETE_NOTIFICATION: (notificationId) => `${BASE_URL}/cards/api/notifications/delete/${notificationId}/`,
  MARK_AS_READ: (notificationId) => `${BASE_URL}/cards/api/notifications/mark-as-read/${notificationId}/`,
  MARK_AS_UNREAD: (notificationId) => `${BASE_URL}/cards/api/notifications/mark-as-unread/${notificationId}/`,
  TOGGLE_FLAG: (cardUuid) => `${BASE_URL}/cards/api/cards/${cardUuid}/flag/`,
  SET_PROFILE_CARD: (cardUuid) => `${BASE_URL}/cards/api/profile/set-featured-card/${cardUuid}/`,
  DELETE_CARD: (cardUuid) => `${BASE_URL}/cards/api/cards/${cardUuid}/delete/`,

  BLOCK_USER: (profileUuid) => `${BASE_URL}/cards/api/cards/${profileUuid}/block/`,
  SEND_FRIEND_REQUEST: (profileUuid) => `${BASE_URL}/cards/api/cards/${profileUuid}/send_friend_request/`,
  ACCEPT_FRIEND_REQUEST: (friendRequestId) => `${BASE_URL}/cards/api/cards/accept_friend_request/${friendRequestId}/`,
  DELETE_FRIEND_REQUEST: (friendRequestId) => `${BASE_URL}/cards/api/cards/delete_friend_request/${friendRequestId}/`,
  REJECT_FRIEND_REQUEST: (friendRequestId) => `${BASE_URL}/cards/api/cards/reject_friend_request/${friendRequestId}/`,
  REMOVE_FRIEND: (profileUuid) => `${BASE_URL}/cards/api/cards/${profileUuid}/remove_friend/`,
  IS_FRIEND: (profileUuid) => `${BASE_URL}/cards/api/cards/${profileUuid}/is_friend/`,
  FRIEND_SHARED_WORLDS: (profileUuid) => `${BASE_URL}/cards/api/friend_shared_worlds/${profileUuid}/`,
  FRIEND_SHARED_BIG_WORLDS: (profileUuid) => `${BASE_URL}/cards/api/friend_shared_big_worlds/${profileUuid}/`,
  WORLD_NAVIGATION: (worldUuid, direction) => `${BASE_URL}/cards/api/world-navigation/${worldUuid}/?direction=${direction}`,
  WORLD_COLLABORATORS_SEARCH: (worldUuid) => `${BASE_URL}/cards/api/world-collaborators-search/${worldUuid}`,
  WORLD_CARDS: (worldUuid) => `${BASE_URL}/cards/api/worlds/${worldUuid}/cards/`,

  CARD_AUDIO: (uuid) => `${BASE_URL}/cards/api/cards/${uuid}/audio/`,

};


const API_ENDPOINTS = {
  ...STATIC_ENDPOINTS,
  generate: DYNAMIC_ENDPOINTS,
};

export default API_ENDPOINTS;

