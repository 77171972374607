import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  Pressable,
  Modal,
} from 'react-native';
import {ImageBackground} from "expo-image";
import TreeGraph from '../components/TreeGraph';
import HorizontalCard from "../components/HorizontalCard";
import { fetchWorldTree } from '../utils/api/cardsService';
import theme from '../styles/theme';
import { useNavigation } from '@react-navigation/native';

const TreeScreen = ({ route }) => {
  const { worldUuid, cardUuid } = route.params;
  const [treeData, setTreeData] = useState(null);
  const [worldName, setWorldName] = useState('');
  const [worldArt, setWorldArt] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false); // Tooltip state
  const navigation = useNavigation();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWorldTree(worldUuid);
        setTreeData(data.tree.tree);
        setWorldName(data.tree.name);
        setWorldArt(data.tree.art); // Set the art URL
        setSelectedCard(data.tree.tree);
      } catch (error) {
        console.error('Error fetching tree data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [worldUuid]);

  if (isLoading) {
    return (
      <View style={styles.loader}>
        <ActivityIndicator size="large" color={theme.colors.bluePrimary} />
      </View>
    );
  }

  if (!treeData) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Failed to load tree data.</Text>
      </View>
    );
  }

  const handleCardPress = (card) => {
    setSelectedCard(card); // Update the selected card when a card tile is tapped
  };

  const handleHorizontalCardPress = () => {
    if (selectedCard) {
      navigation.push('CardDetail', {card_uuid: selectedCard.uuid});
    }
  };

  const handleTooltipPress = () => {
    setIsTooltipVisible(true);
  };

  return (
    <ImageBackground
      source={worldArt ? { uri: worldArt } : null}
      style={styles.container}
      imageStyle={{ opacity: 0.15 }} // Adjust opacity for a faded effect
    >
      <View style={styles.headerArea}>
        <View style={styles.titleContainer}>
          <View style={styles.titleWrapper}>
            {!worldName ? (
              <TouchableOpacity onPress={handleTooltipPress}
                                style={styles.touchableTitle}>
                <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">
                  {"Unnamed World"}
                </Text>
                <Text style={styles.tooltipIcon}>ⓘ</Text>
              </TouchableOpacity>
            ) : (
              <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">
                {worldName}
              </Text>
            )}
          </View>
        </View>
        {/*{selectedCard && (*/}
          <HorizontalCard
            card={selectedCard}
            onPress={handleHorizontalCardPress} // Navigate on horizontal card tap
          />
        {/*)}*/}
      </View>
      <View
        style={styles.treeContainer}
        onLayout={(e) => {
          setContainerWidth(e.nativeEvent.layout.width);
          setContainerHeight(e.nativeEvent.layout.height);
        }}
      >
        {containerWidth > 0 && containerHeight > 0 && (
          <TreeGraph
            data={treeData}
            onCardPress={handleCardPress} // Update selected card on tree card tap
            containerWidth={containerWidth}
            containerHeight={containerHeight}
            selectedCardUuid={cardUuid}
          />
        )}
      </View>
      {isTooltipVisible && (
        <Modal
          animationType="fade"
          transparent={true}
          visible={isTooltipVisible}
          onRequestClose={() => setIsTooltipVisible(false)}
        >
          <View style={styles.modalContainer}>
            <Pressable style={styles.modalBackdrop} onPress={() => setIsTooltipVisible(false)} />
            <View style={styles.modalContent}>
              <Text style={styles.modalText}>
                Your world will get a name once it has 4 or more cards.
              </Text>
              <TouchableOpacity onPress={() => setIsTooltipVisible(false)}>
                <Text style={styles.modalCloseButton}>CLOSE</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.beige, // Fallback color
  },
  treeContainer: {
    flex: 1,
    zIndex: 1,
  },
  headerArea: {
    zIndex: 20,
  },
  titleContainer: {
    backgroundColor: theme.colors.gray,
    padding: theme.spacings.medium,
    width: '100%',
    // marginBottom: theme.spacings.medium,
    zIndex: 10,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.colors.beige,
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.yellowPrimary,
    textAlign: 'center',
  },
  tooltipIcon: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.white,
    marginLeft: theme.spacings.xsmall,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.redPrimary,
    textAlign: 'center',
  },
    modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalBackdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modalContent: {
    backgroundColor: theme.colors.gray,
    padding: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    width: '80%',
    alignItems: 'center',
  },
  touchableTitle: {
    flexDirection: 'row', // Title and icon in a row
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
  },
  modalText: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.white,
    textAlign: 'center',
    marginBottom: theme.spacings.medium,
  },
  modalCloseButton: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.yellowPrimary,
    textDecorationLine: 'underline',
  },
});


export default TreeScreen;
