import { useQuery } from 'react-query';
import { fetchWorld } from '../utils/api/cardsService';

export const useWorldDetails = (worldUuid) => {
  return useQuery(['worldDetails', worldUuid], () => fetchWorld(worldUuid), {
    // staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 10 * 60 * 1000, // 10 minutes
    onSuccess: (data) => {
      console.log('API Response:', data);
    },
  });
};
