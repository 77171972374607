import React, { useRef, useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Platform,
  Dimensions,
} from 'react-native';
import { Image } from 'expo-image';
import { Ionicons } from '@expo/vector-icons';
import { useCardStyles } from '../hooks/useCardStyles';
import theme from '../styles/theme';
import { useSubscription } from '../hooks/useSubscription';
import SpeedIndicator from "./SpeedIndicator";
import { useNavigation } from "@react-navigation/native";

const STYLE_PREVIEW_WIDTH = 130;
const STYLE_PREVIEW_HEIGHT = STYLE_PREVIEW_WIDTH * 1;
const LABEL_HEIGHT = 40;

const NavigationButtons = ({ onPressNext, onPressPrev, showPrev, showNext }) => {
  if (Platform.OS !== 'web') return null;

  return (
    <>
      {showPrev && (
        <TouchableOpacity
          style={[styleSheet.navButton, styleSheet.prevButton]}
          onPress={onPressPrev}
          hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <Ionicons name="chevron-back" size={24} color={theme.colors.beige} />
        </TouchableOpacity>
      )}
      {showNext && (
        <TouchableOpacity
          style={[styleSheet.navButton, styleSheet.nextButton]}
          onPress={onPressNext}
          hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <Ionicons name="chevron-forward" size={24} color={theme.colors.beige} />
        </TouchableOpacity>
      )}
    </>
  );
};

const CardStyleSelector = ({selectedStyle, onStyleSelect}) => {
  const {data: cardStyles, isLoading, error} = useCardStyles();
  const {isDeveloper, hasSubscription} = useSubscription();
  const navigation = useNavigation();
  const scrollViewRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);

  const showPrevButton = scrollPosition > 0;
  const showNextButton = scrollPosition < maxScroll;
  const screenWidth = Dimensions.get('window').width; // Get screen width dynamically
  const VISIBLE_CONTAINER_WIDTH = Math.min(screenWidth, 600); // Use 600px max width for larger screens


  const onContentSizeChange = (contentWidth) => {
    const scrollWidth = contentWidth - VISIBLE_CONTAINER_WIDTH;
    setMaxScroll(Math.max(0, scrollWidth));
  };

  const scrollOneCard = (direction) => {
    if (scrollViewRef.current) {
      const newPosition = direction === 'next'
        ? scrollPosition + 2 * (STYLE_PREVIEW_WIDTH + theme.spacings.small)
        : scrollPosition - 2 * (STYLE_PREVIEW_WIDTH + theme.spacings.small);

      const clampedPosition = Math.max(0, Math.min(newPosition, maxScroll));

      scrollViewRef.current.scrollTo({
        x: clampedPosition,
        animated: true
      });
      setScrollPosition(clampedPosition);
    }
  };

  if (isLoading) {
    return (
      <View style={styleSheet.loadingContainer}>
        <ActivityIndicator size="large" color={theme.colors.lightgray}/>
      </View>
    );
  }

  if (error) {
    return null;
  }

  return (
    <View style={[styleSheet.container, { width: VISIBLE_CONTAINER_WIDTH }]}>
      <Text style={styleSheet.title}>CHOOSE A STYLE</Text>
      <View style={styleSheet.visibleContainer}>
        <ScrollView
          ref={scrollViewRef}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styleSheet.scrollContent}
          style={styleSheet.scrollView}
          keyboardShouldPersistTaps="handled"
          onScroll={(event) => setScrollPosition(event.nativeEvent.contentOffset.x)}
          scrollEventThrottle={16}
          onContentSizeChange={(contentWidth) => onContentSizeChange(contentWidth)}
        >
          <View style={styleSheet.row}>
            {cardStyles
              .filter((style) => isDeveloper || !style.is_developer_only)
              .map((style) => {
                const isLocked = style.is_premium && !hasSubscription;
                return (
                  <TouchableWithoutFeedback key={style.name}>
                    <TouchableOpacity
                      style={[
                        styleSheet.styleItem,
                        selectedStyle === style.name && styleSheet.selectedStyle,
                        isLocked && styleSheet.lockedStyle,
                        style.is_premium && styleSheet.premiumStyle,
                      ]}
                      onPress={() =>
                        isLocked
                          ? navigation.navigate('UpgradeScreen')
                          : onStyleSelect(selectedStyle === style.name ? null : style.name)
                      }
                      disabled={!isLocked && isLocked}
                    >
                      <View style={styleSheet.styleContainer}>
                        <View style={styleSheet.imageContainer}>
                          <Image
                            source={{uri: style.image_uri}}
                            style={styleSheet.previewImage}
                          />
                          {isLocked && (
                            <View style={styleSheet.lockedBanner}>
                              <Text style={styleSheet.lockedBannerText}>PREMIUM</Text>
                            </View>
                          )}
                          {selectedStyle === style.name && (
                            <View style={styleSheet.checkmarkContainer}>
                              <View style={styleSheet.checkmarkCircle}>
                                <Text style={styleSheet.checkmark}>✓</Text>
                              </View>
                            </View>
                          )}
                        </View>
                        <View
                          style={[
                            styleSheet.labelContainer,
                            style.is_premium && styleSheet.premiumLabelContainer,
                            selectedStyle === style.name && styleSheet.selectedLabelContainer,
                            isLocked && styleSheet.lockedLabelContainer,
                          ]}
                        >
                          <View style={styleSheet.iconContainer}>
                            <SpeedIndicator speed={style.speed}/>
                          </View>
                          <View style={styleSheet.textContainer}>
                            <Text
                              style={[
                                styleSheet.styleLabel,
                                style.is_premium && styleSheet.premiumLabel,
                                isLocked && styleSheet.lockedLabel,
                                selectedStyle === style.name && styleSheet.selectedLabel,
                              ]}
                            >
                              {style.display_name}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </TouchableWithoutFeedback>
                );
            })}
          </View>
        </ScrollView>
        <NavigationButtons
          onPressPrev={() => scrollOneCard('prev')}
          onPressNext={() => scrollOneCard('next')}
          showPrev={showPrevButton}
          showNext={showNextButton}
        />
      </View>
    </View>
  );
};

const styleSheet = StyleSheet.create({
  container: {
    height: STYLE_PREVIEW_HEIGHT + LABEL_HEIGHT + 20,
    position: 'relative',
  },
  visibleContainer: {
    position: 'relative',
    maxWidth: '100%',
    height: STYLE_PREVIEW_HEIGHT + LABEL_HEIGHT,
    alignSelf: 'center',
    overflow: 'hidden',
  },
  title: {
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.gray,
    marginLeft: theme.spacings.medium,
    fontFamily: theme.fonts.family.bold,
    marginBottom: theme.spacings.xsmall,
  },
  scrollView: {
  },
  scrollContent: {
    paddingHorizontal: theme.spacings.medium,
  },
  row: {
    flexDirection: 'row',
  },
  styleContainer: {
    width: '100%',
    flexDirection: 'column',
  },
  imageContainer: {
    width: '100%',
    height: STYLE_PREVIEW_HEIGHT,
    overflow: 'hidden',
  },
  selectedLabelContainer: {
    backgroundColor: theme.colors.bluePrimary,
  },
  premiumLabelContainer: {
  },
  lockedLabelContainer: {
  },
  styleLabel: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.small,
    textAlign: 'center',
    fontFamily: theme.fonts.family.medium,
    textTransform: 'uppercase',
  },
  premiumLabel: {
    color: theme.colors.yellowPrimary,
  },
  selectedLabel: {
    color: theme.colors.white,
    fontFamily: theme.fonts.family.bold,
  },
  lockedLabel: {
  },
  loadingContainer: {
    height: STYLE_PREVIEW_HEIGHT + LABEL_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    color: theme.colors.lightgray,
    fontFamily: theme.fonts.family.medium,
  },
  styleItem: {
    width: STYLE_PREVIEW_WIDTH,
    height: STYLE_PREVIEW_HEIGHT + LABEL_HEIGHT,
    borderRadius: theme.borderRadius.medium,
    borderWidth: 1,
    borderColor: theme.colors.lightgray,
    overflow: 'hidden',
    marginRight: theme.spacings.small,
    backgroundColor: theme.colors.loadingBackground,
  },
  lockedStyle: {
    opacity: 0.7,
  },
  premiumStyle: {
    borderColor: theme.colors.yellowPrimary,
  },
  selectedStyle: {
    borderColor: theme.colors.bluePrimary,
  },
  previewImage: {
    width: '100%',
    height: '100%',
  },
  checkmarkContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  checkmarkCircle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: theme.colors.lighterBlue,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkmark: {
    color: theme.colors.white,
    fontSize: 16,
    fontWeight: 'bold',
  },
  labelContainer: {
    width: '100%',
    height: LABEL_HEIGHT,
    backgroundColor: 'rgba(0,0,0,0.7)',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    position: 'absolute',
    left: 1,
    bottom: 12,
    height: '100%',
    justifyContent: 'center',
  },
  textContainer: {
    width: '95%',
    flex: 1,
    alignItems: 'center',
  },
  lockedBanner: {
    position: 'absolute',
    top: 0,
    width: '100%',
    backgroundColor: theme.colors.yellowPrimary,
    paddingVertical: 4,
    alignItems: 'center',
    zIndex: 1,
  },
  lockedBannerText: {
    color: theme.colors.gray,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.small,
    textTransform: 'uppercase',
  },
  navButton: {
    position: 'absolute',
    top: '50%',
    transform: [{translateY: -20}],
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: theme.colors.slightly_transparent_gray,
    borderColor: theme.colors.beige,
    borderWidth: StyleSheet.hairlineWidth,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    display: 'flex',
  },
  prevButton: {
    left: 1,
  },
  nextButton: {
    right: 1,
  },
});

export default CardStyleSelector;
