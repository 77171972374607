import React, { useEffect, useRef } from 'react';
import { Animated, TouchableOpacity, Text, View, StyleSheet } from 'react-native';
import { theme } from '../../styles/theme';
import { cardScaleSize } from '../../styles/theme';
import { cardWidth } from '../Card';
import SvgRiff from "../../assets/images/Riff";
import { LinearGradient } from 'expo-linear-gradient';

const AnimatedBottomRiffBox = ({ onPress, showAnimation }) => {
  const shakeAnim = useRef(new Animated.Value(0)).current;
  const sheenAnim = useRef(new Animated.Value(0)).current; // For the sheen effect

  useEffect(() => {
    const id = shakeAnim.addListener(() => {});
    return () => shakeAnim.removeListener(id);
  }, []);

  useEffect(() => {
    if (!showAnimation) return;

    const animationTimeout = setTimeout(() => {
      // Jiggle sequence
      Animated.sequence([
        Animated.timing(shakeAnim, {
          toValue: 10,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(shakeAnim, {
          toValue: -10,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(shakeAnim, {
          toValue: 10,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(shakeAnim, {
          toValue: -10,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(shakeAnim, {
          toValue: 0,
          duration: 100,
          useNativeDriver: true,
        }),
      ]).start(() => {
        // After jiggle, start sheen if still animating
        if (showAnimation) {
          startSheenLoop();
        }
      });
    }, 200);

    return () => {
      clearTimeout(animationTimeout);
    };
  }, [showAnimation]);

  const startSheenLoop = () => {
    sheenAnim.setValue(0);
    Animated.loop(
      Animated.timing(sheenAnim, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: true,
      })
    ).start();
  };

  // We use interpolation to move a narrow gradient band across the text.
  // The gradient will move from left to right across the text container.
  const textWidth = cardScaleSize(cardWidth) * 0.8; // Approx width for text area
  const translateX = sheenAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [-textWidth, textWidth], // Moves from left to right
  });

  return (
    <View style={styles.container}>
      <Animated.View style={[
        styles.riffButtonContainer,
        { transform: [{ translateX: shakeAnim }] }
      ]}>
        <TouchableOpacity onPress={onPress} style={styles.riffButton}>
          <View style={styles.riffButtonContent}>
            {/* Text Container with overflow: hidden for sheen */}
            <View style={styles.textContainer}>
              <Text style={styles.riffButtonText}>RIFF THE NEXT CHAPTER</Text>
              {showAnimation && (
                <Animated.View
                  style={[
                    styles.sheenOverlay,
                    { transform: [{ translateX }] }
                  ]}
                >
                  <LinearGradient
                    colors={['transparent', 'rgba(255,255,255,0.3)', 'transparent']}
                    locations={[0, 0.5, 1]}
                    start={{ x: 0, y: 0.5 }}
                    end={{ x: 1, y: 0.5 }}
                    style={styles.gradient}
                  />
                </Animated.View>
              )}
            </View>
            <SvgRiff />
          </View>
        </TouchableOpacity>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1002,
  },
  riffButtonContainer: {
    width: cardScaleSize(cardWidth),
    zIndex: 1005,
  },
  riffButton: {
    width: '100%',
    paddingVertical: theme.spacings.medium,
    marginTop: 10,
    backgroundColor: theme.colors.gray,
    borderRadius: 10,
    marginBottom: theme.spacings.large,
    overflow: 'hidden',
  },
  riffButtonContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  riffButtonText: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.scaledSizes.large,
    marginRight: theme.spacings.small,
  },
  sheenOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '40%', // narrow strip of shine
  },
  gradient: {
    flex: 1,
  }
});

export default AnimatedBottomRiffBox;
