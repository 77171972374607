import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons'; // Ensure this package is installed
import theme from "../styles/theme";

const CreateInstructions = ({ style }) => {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.tooltipBox}>
        <View style={styles.bulletContainer}>
          <Text style={styles.bulletText}>
            Enter <Text style={styles.anythingText}>ANYTHING</Text> for your story title below.
          </Text>
        </View>
        <View style={styles.bulletContainer}>
          <Text style={styles.bulletText}>
            Or use the <Text style={styles.highlight}>INSPIRE ME</Text> button for suggestions.
          </Text>
        </View>
        <View style={styles.bulletContainer}>
          <Text style={styles.bulletText}>
            Press <Text style={styles.highlightBlue}>CREATE</Text> to start your story.
          </Text>
        </View>
      </View>
      {/* Arrow pointing down */}
      <FontAwesome name="long-arrow-down" size={42} color={theme.colors.gray} style={styles.arrow} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  tooltipBox: {
    backgroundColor: theme.colors.gray,
    paddingHorizontal: theme.spacings.large,
    paddingVertical: theme.spacings.small,
    borderRadius: theme.borderRadius.medium,
    marginHorizontal: theme.spacings.small,
    marginVertical: 0,
    maxWidth: 400,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: 'flex-start',
  },
  bulletContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 4,
  },
  bulletPoint: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    marginRight: theme.spacings.xsmall,
  },
  bulletText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
    lineHeight: 24,
  },
  anythingText: {
    fontFamily: theme.fonts.family.bold,
    textDecorationLine: 'underline',
  },
  highlight: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.bold,
    textDecorationLine: 'underline',
  },
  highlightBlue: {
    color: theme.colors.lighterBlue,
    fontFamily: theme.fonts.family.bold,
    textDecorationLine: 'underline',
  },
  arrow: {
    // marginTop: theme.spacings.xsmall,
  },
});

export default CreateInstructions;
