import React from 'react';
import { View, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import theme from "../styles/theme";

const SpeedIndicator = ({ speed, color }) => {
  const getIconProps = () => {
    switch (speed) {
      case 'fast':
        return {
          name: 'lightning-bolt',
          color: color || theme.colors.yellowPrimary,
          size: 16
        };
      case 'medium':
        return {
          name: 'walk',
          color: color || theme.colors.yellowPrimary,
          size: 16
        };
      case 'slow':
        return {
          name: 'snail',
          color: color || theme.colors.yellowPrimary,
          size: 16
        };
      case 'unknown':
        return {
          // name: 'help-circle-outline',
          // color: color || theme.colors.yellowPrimary,
          // size: 16
        };
      default:
        return null;
    }
  };

  const iconProps = getIconProps();
  if (!iconProps) return null;

  return (
    <View style={styles.container}>
      <MaterialCommunityIcons {...iconProps} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // backgroundColor: 'rgba(0, 0, 0, 0.7)',
    // borderRadius: 12,
    // padding: 4,
  }
});

export default SpeedIndicator;
