import { Platform, Share } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import alert from './alert';

const djangoUrl = process.env.EXPO_PUBLIC_API_URL;
const APP_STORE = process.env.EXPO_PUBLIC_IOS_APP_URL;
const djangoCardUrl = `${djangoUrl}/cards/card_link`;
const djangoProfileUrl = `${djangoUrl}/cards/profile_link`;
const djangoWorldUrl = `${djangoUrl}/cards/world_link`;  // Add this new URL

export const shareContent = async (type, uuid) => {
  let fullUrl;
  let message;

  switch(type) {
    case 'profile':
      fullUrl = `${djangoProfileUrl}/${uuid}`;
      break;
    case 'world':
      fullUrl = `${djangoWorldUrl}/${uuid}`;
      break;
    case 'app':
      fullUrl = APP_STORE;
      // message = "Join me on Fabyl to create stories together! Download the app:";
      break;
    case 'card':
    default:
      fullUrl = `${djangoCardUrl}/${uuid}`;
  }

  if (Platform.OS === 'web') {
    try {
      await Clipboard.setStringAsync(fullUrl);
      alert('Link Copied', 'The link has been copied to your clipboard.');
    } catch (error) {
      console.error('Error copying link to clipboard:', error);
      alert('Error', 'Failed to copy the link to your clipboard.');
    }
  } else {
    try {
      let shareMessage = fullUrl;
      if (message) {
        shareMessage = `${message}\n${fullUrl}`;  // Added newline for better readability
      }

      const result = await Share.share({
        message: shareMessage
      });

      if (result.action === Share.dismissedAction) {
        console.log('Share dismissed');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      alert('Error', error.message);
    }
  }
};
