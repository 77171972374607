// hooks/useSubscription.js
import { useQuery } from 'react-query';
import { fetchSubscriptionData } from '../utils/api/userService';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export const useSubscription = (refetchOnFocus = false) => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const userId = user?.attributes?.sub;

  const { data, isLoading, isError } = useQuery(
    ['subscription', userId],
    fetchSubscriptionData,
    {
      enabled: isAuthenticated && !!userId,
      refetchOnWindowFocus: refetchOnFocus,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 15 * 60 * 1000, // 15 minutes
    }
  );

  // Check if the user has a subscription
  const hasSubscription = !!data && !!data.subscription_id;

  // Check if the subscription tier is 'developer'
  const isDeveloper = hasSubscription && data.tier === 'developer';

  return {
    subscriptionData: data,
    isLoading,
    isError,
    hasSubscription, // Expose whether the user is premium
    isDeveloper, // Expose whether the user is a developer
  };
};
