import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {StyleSheet, Text, View, TouchableWithoutFeedback} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {FontAwesome} from '@expo/vector-icons';
import SvgFabylHeader from "../assets/images/FabylHeader";
import theme from "../styles/theme";
import HorizontalMiniCardGallery from "../components/HorizontalMiniCardGallery";
import globalStyles from "../styles/globalStyles";
import SearchButton from "../components/SearchButton";
import NotificationsButton from "../components/NotificationsButton";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import useCards from "../hooks/useCards";
import API_ENDPOINTS from '../apiEndpoints';
import {useIsFocused} from "@react-navigation/native";
import {getScrollPosition, setScrollPosition} from '../utils/scrollPositionManager';
import CreateButton from "../components/buttons/CreateButton";
import HorizontalMiniCardGallerySkeleton from "../components/skeletons/HorizontalMiniCardSkeletonGallery";
import useWorlds from "../hooks/useWorlds";
import TinyWorldComponent from "../components/TinyWorldComponent";
import {useProfile} from "../contexts/ProfileContext";
import CreateTooltipOverlay from "../components/modals/CreateTooltipOverlay";

function CreateScreen({ navigation }) {
  const isLargeScreen = useIsLargeScreen();
  const scrollPositionRef = useRef(null);
  const [isGalleryReady, setIsGalleryReady] = useState(false);
  const isFocused = useIsFocused();
  const { getFeaturedWorld } = useWorlds();
  const { profileData, markCreateTooltipSeen } = useProfile();
  const [showTooltip, setShowTooltip] = useState(false);
  const { data: featuredWorld, isLoading, error } = getFeaturedWorld();
  const buttonRef = useRef(null);

  useEffect(() => {
    if (profileData && !profileData.create_tooltip_seen) {
      const timer = setTimeout(() => {
        setShowTooltip(true);
      }, 500); // Slight delay for smooth appearance
      return () => clearTimeout(timer);
    }
  }, [profileData]);

  useEffect(() => {
    if (isFocused) {
      const savedPosition = getScrollPosition('CreateScreen');
      scrollPositionRef.current = savedPosition;
      setIsGalleryReady(true);
    }
  }, [isFocused]);

  const handleScrollPositionChange = useCallback((position) => {
    scrollPositionRef.current = position;
    setScrollPosition('CreateScreen', position);
  }, []);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: !isLargeScreen ? () => (
        <View style={globalStyles.headerRightContainer}>
          <NotificationsButton navigation={navigation}/>
          <SearchButton
            navigation={navigation}
            targetScreen='Search'
            facetKey='myCards'
          />
        </View>
      ) : null,
      headerShown: !isLargeScreen,
    });
  }, [navigation, isLargeScreen]);

  const {
    cards,
    nextPageURL,
    loadingCards,
    fetchMoreCards,
  } = useCards(API_ENDPOINTS.RANDOM_CARDS);

  const handleCreatePress = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
    if (!profileData.create_tooltip_seen) {
      markCreateTooltipSeen();
    }
    navigation.navigate('ExpandedInput');
  };

  return (
    <View style={styles.mainContainer}>
      {showTooltip && (
        <CreateTooltipOverlay
          isVisible={showTooltip}
          // onClose={() => {
          //   setShowTooltip(false);
          //   markCreateTooltipSeen();
          // }}
          buttonRef={buttonRef}
        />
      )}

      <View style={styles.headerSection}>
        <View style={styles.fabylHeaderContainer}>
          <SvgFabylHeader />
          <View style={styles.brandContainer}>
            <Text style={globalStyles.brandPhrase}>weave whimsical worlds</Text>
          </View>
        </View>
      </View>

      <View style={styles.gallerySection}>
        {isFocused && scrollPositionRef.current !== null && (!loadingCards || cards) ? (
          <HorizontalMiniCardGallery
            cards={cards}
            nextPageURL={nextPageURL}
            loadMore={fetchMoreCards}
            loading={loadingCards}
            initialScrollPosition={scrollPositionRef.current}
            onScrollPositionChange={handleScrollPositionChange}
          />
        ) : (
          <HorizontalMiniCardGallerySkeleton />
        )}
      </View>

      <View style={styles.featuredWorldSection}>
        <View style={styles.featuredWorldTitleContainer}>
          <Ionicons name="planet-outline" size={24} color={theme.colors.yellowSecondary} />
          <Text style={styles.featuredWorldTitle}>Featured World</Text>
        </View>
        <TinyWorldComponent world={featuredWorld}/>
      </View>

      <View
        style={[
          styles.createSection,
          isLargeScreen ? { justifyContent: 'flex-start' } : null,
        ]}
        ref={buttonRef}
      >
        <CreateButton
          onPress={handleCreatePress}
          text="NAME YOUR FABYL..."
          // style={showTooltip && styles.highlightedButton}
          showTooltip={showTooltip}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  tooltipOverlay: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1000,
  },
  dimmedBackground: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  tooltipContent: {
    position: 'absolute',
    bottom: '18%', // Adjust based on your layout
    alignSelf: 'center',
    alignItems: 'center',
    zIndex: 1001,
  },
  tooltipBox: {
    backgroundColor: theme.colors.beige,
    padding: 5,
    paddingHorizontal: 20,
    borderRadius: theme.borderRadius.large,
    borderWidth: 0,
  },
  tooltipText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    textAlign: 'center',
    marginBottom: theme.spacings.xsmall,
    textTransform: 'uppercase'
  },
  tooltipArrow: {
    transform: [{rotate: 'deg'}],
  },
  headerSection: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  createSection: {
    flex: 1,
    alignSelf: 'center',
    maxWidth: 600,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1002, // Ensure button stays above overlay
  },
  gallerySection: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  fabylHeaderContainer: {
    alignSelf: 'center',
    alignItems: 'center',
  },
  brandContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  featuredWorldSection: {
    marginVertical: 20,
    alignItems: 'center',
  },
  featuredWorldTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  featuredWorldTitle: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.yellowSecondary,
    textTransform: 'uppercase',
    marginLeft: 8,
  },
  highlightedButton: {
    borderColor: theme.colors.yellowPrimary,
    borderWidth: 2,
    backgroundColor: theme.colors.white,
  }
});

export default React.memo(CreateScreen);
