import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { theme } from '../styles/theme';
import SvgRiff from "../assets/images/Riff";

const RiffInlineText = ({ color = theme.colors.yellowPrimary }) => (
  <View style={styles.container}>
    <Text style={[styles.text, { color }]}>RIFF</Text>
    <View style={styles.iconContainer}>
      <SvgRiff style={styles.icon} fill={color} />
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: -3,
  },
  text: {
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.large,
  },
  iconContainer: {
    marginHorizontal: theme.spacings.xsmall,
  },
  icon: {
    transform: [{ scale: 0.75 }]
  }
});

export default RiffInlineText;
