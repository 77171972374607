import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';
import { theme } from '../../styles/theme';
import SvgRiff from "../../assets/images/Riff";
import RiffInlineText from "../RiffInlineText";

const FirstRiffModal = ({ isVisible, onClose }) => {
  return (
    <Modal visible={isVisible} transparent animationType="fade">
      <View style={styles.container}>
        <View style={styles.overlay} />

        <View style={styles.modalContent}>
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <MaterialIcons name="close" size={24} color={theme.colors.white} />
          </TouchableOpacity>

          <Text style={styles.title}>CREATE YOUR FIRST RIFF</Text>

          <View style={styles.riffIconContainer}>
            <SvgRiff />
          </View>

          <Text style={styles.modalText}>
            Time to continue the story! {"\n\n"}Click the <RiffInlineText /> button below to add the next chapter.
          </Text>

          <TouchableOpacity style={styles.actionButton} onPress={onClose}>
            <Text style={styles.buttonText}>GOT IT!</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: theme.colors.gray,
    marginHorizontal: 20,
    borderRadius: theme.borderRadius.large,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.yellowPrimary,
    padding: 20,
    alignItems: 'center',
    zIndex: 1002,
  },
  title: {
    color: theme.colors.yellowPrimary,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    marginBottom: 15,
    textAlign: 'center',
  },
  riffIconContainer: {
    marginBottom: 15,
    transform: [{ scale: 1.2 }]
  },
  modalText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.regular,
    marginBottom: 20,
    lineHeight: 24,
  },
  highlightText: {
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.yellowPrimary,
  },
  inlineSvg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 4,
    marginTop: -4,
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    padding: 5,
  },
  actionButton: {
    backgroundColor: theme.colors.yellowPrimary,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: theme.borderRadius.medium,
    marginTop: 10,
  },
  buttonText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
});

export default FirstRiffModal;
