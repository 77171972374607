import React, {useState} from 'react';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Modal,
  SafeAreaView,
  Linking,
  ActivityIndicator,
} from 'react-native';
import {Image} from 'expo-image';
import {useRoute} from '@react-navigation/native';
// import {useQuery} from 'react-query';
// import {fetchCardByUUID} from '../utils/api/cardsService';
import {useCardDeveloperDetails} from '../hooks/useCardDeveloperDetails';
import {MaterialIcons, AntDesign} from '@expo/vector-icons';
import theme from '../styles/theme';
import JSONTree from 'react-native-json-tree'; // Add this import at the top
import alert from "../utils/alert";
import * as Clipboard from 'expo-clipboard';


const CardDeveloperDetails = () => {
  const route = useRoute();
  const {cardUuid} = route.params;

  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [isPromptModalVisible, setPromptModalVisible] = useState(false);
  const [isWrapped, setIsWrapped] = useState(false);
  const [isConfigModalVisible, setConfigModalVisible] = useState(false);

  const formatPrompt = (prompt) => {
    if (Array.isArray(prompt)) {
      // If prompt is an array of objects with role/content
      if (prompt[0] && typeof prompt[0] === 'object' && 'role' in prompt[0]) {
        return prompt.map(msg =>
          `[${msg.role.toUpperCase()}]:\n${msg.content}\n`
        ).join('\n---\n');
      }
      // If it's just an array of strings
      return prompt.join('\n---\n');
    }
    // If it's just a string
    return prompt;
  };

  // const {data: card, isLoading, isError} = useQuery(['cardDetail', cardUuid], () =>
  //   fetchCardByUUID(cardUuid)
  // );
  const {developerDetails: card, isLoading, isError} = useCardDeveloperDetails(cardUuid);

  if (isLoading) {
    // return <Text>Loading...</Text>;
    return <ActivityIndicator size="large" color={theme.colors.gray}/>;
  }

  if (isError) {
    return <Text>Error loading card developer details</Text>;
  }

  const handleSearchOnGoogle = (text) => {
    const url = `https://www.google.com/search?q=${encodeURIComponent(text)}`;
    Linking.openURL(url).catch((err) => console.error('An error occurred', err));
  };

  const handleCopyPrompt = () => {
    Clipboard.setString(selectedPrompt);
    // Optional: Add some feedback that text was copied
    alert('Copied', 'Prompt copied to clipboard');
  };

  const handleOpenPromptModal = (prompt) => {
    setSelectedPrompt(formatPrompt(prompt));
    setPromptModalVisible(true);
  };

  const handleClosePromptModal = () => {
    setPromptModalVisible(false);
    setSelectedPrompt('');
  };

  const handleOpenConfigModal = () => {
    setConfigModalVisible(true);
  };

  const handleCloseConfigModal = () => {
    setConfigModalVisible(false);
  };

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.title}>Developer Details</Text>

      {/* Basic Card Info */}
      <View style={styles.cardContainer}>
        <Image source={{uri: card.thumbnail}} style={styles.cardThumbnail}/>
        <View style={styles.cardTopRow}>
          <Text style={styles.cardName}>{card.name}</Text>
        </View>
        <Text style={styles.cardDescription}>{card.flavor}</Text>
      </View>

      {/* Config Section */}
      <View style={styles.sectionContainer}>
        <TouchableOpacity
          style={styles.promptButton} // Reuse existing styles for buttons
          onPress={handleOpenConfigModal}
        >
          <Text style={styles.promptButtonText}>View Config</Text>
        </TouchableOpacity>
      </View>

      {/* Prompts Section */}
      {card.prompts && card.prompts.length > 0 && (
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Prompts</Text>
          {card.prompts.map((prompt, index) => (
            <TouchableOpacity
              key={index}
              style={styles.promptButton}
              onPress={() => handleOpenPromptModal(prompt)}
            >
              <Text style={styles.promptButtonText}>
                View Prompt {index + 1}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      )}

      {/* Style Info */}
      {card.style && (
        <View style={styles.styleContainer}>
          <Text style={styles.styleTitle}>STYLE IMPLEMENTATION:</Text>
          <View style={styles.styleBox}>
            <Text style={styles.styleName}>{card.style}</Text>
          </View>
        </View>
      )}

      {/* Diversifiers */}
      {card.diversifiers && (
        <View style={styles.diversifiersContainer}>
          <Text style={styles.diversifiersTitle}>DIVERSIFIERS:</Text>
          {card.diversifiers.map((diversifier, index) => (
            <View
              key={diversifier.id}
              style={[
                styles.diversifierItem,
                index % 2 === 0 ? styles.evenItem : styles.oddItem,
              ]}
            >
              <TouchableOpacity
                style={styles.searchIcon}
                onPress={() => handleSearchOnGoogle(diversifier.name)}
              >
                <MaterialIcons name="search" size={34} color={theme.colors.gray}/>
              </TouchableOpacity>
              <Text style={styles.diversifierType}>{diversifier.diversifier_type}</Text>
              <Text style={styles.diversifierName}>{diversifier.name}</Text>
              <Text
                style={styles.diversifierDescription}>{diversifier.description}</Text>
              {index < card.diversifiers.length - 1 && <View style={styles.divider}/>}
            </View>
          ))}
        </View>
      )}

      {/* Prompt Modal */}
      <Modal
        visible={isPromptModalVisible}
        animationType="slide"
        onRequestClose={handleClosePromptModal}
      >
        <SafeAreaView style={styles.modalContainer}>
          <View style={styles.modalHeader}>
            <TouchableOpacity
              style={styles.headerButton}
              onPress={() => setIsWrapped(!isWrapped)}
            >
              <MaterialIcons
                name={isWrapped ? "wrap-text" : "format-align-justify"}
                size={24}
                color={theme.colors.gray}
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.headerButton}
              onPress={handleCopyPrompt}
            >
              <MaterialIcons
                name="content-copy"
                size={24}
                color={theme.colors.gray}
              />
            </TouchableOpacity>
          </View>

          {/* Content container with both horizontal and vertical scrolling */}
          <View style={styles.modalScrollContainer}>
            <ScrollView
              horizontal={!isWrapped}
              showsHorizontalScrollIndicator={true}
            >
              <ScrollView
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={true}
              >
                <Text
                  style={[
                    styles.modalText,
                    !isWrapped && styles.modalTextNoWrap
                  ]}
                  selectable={true}
                >
                  {selectedPrompt}
                </Text>
              </ScrollView>
            </ScrollView>
          </View>

          <TouchableOpacity
            style={styles.closeModalButton}
            onPress={handleClosePromptModal}
          >
            <Text style={styles.closeModalButtonText}>Close</Text>
          </TouchableOpacity>
        </SafeAreaView>
      </Modal>

      {/* Config Modal */}
      <Modal
        visible={isConfigModalVisible}
        animationType="slide"
        onRequestClose={handleCloseConfigModal}
      >
        <View style={styles.modalContainer}>
          <ScrollView>
            <View style={styles.jsonViewer}>
              <JSONTree
                data={card.config || {}}
                theme={{
                  base00: theme.colors.background,
                  base07: theme.colors.text,
                  base08: theme.colors.redPrimary,
                  base0B: theme.colors.greenPrimary,
                  base0D: theme.colors.bluePrimary,
                  base0E: theme.colors.yellowPrimary,
                  // nestedNode: {
                  //   fontSize: theme.fonts.scaledSizes.large, // Increase font size here
                  //   lineHeight: theme.fonts.scaledSizes.large * 1.5, // Optional for better readability
                  // },
                  nestedNodeItemString: {
                    fontSize: theme.fonts.large,
                  },
                }}
                invertTheme={false}
                hideRoot={false}
                shouldExpandNode={() => true}
              />
            </View>
          </ScrollView>
          <TouchableOpacity style={styles.closeModalButton}
                            onPress={handleCloseConfigModal}>
            <Text style={styles.closeModalButtonText}>Close</Text>
          </TouchableOpacity>
        </View>
      </Modal>

      <View style={{marginVertical: theme.scaledSpacings.xlarge}}></View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: theme.scaledSpacings.large,
    backgroundColor: theme.colors.background,
  },
  title: {
    fontSize: theme.fonts.scaledSizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.primary,
    marginBottom: theme.scaledSpacings.medium,
  },
  sectionContainer: {
    marginBottom: theme.scaledSpacings.large,
  },
  sectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.scaledSpacings.small,
    backgroundColor: theme.colors.loadingBackground,
    borderRadius: theme.scaledBorderRadius.medium,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.divider,
  },
  sectionTitle: {
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.text,
  },
  configViewer: {
    marginTop: theme.scaledSpacings.small,
    padding: theme.scaledSpacings.medium,
    backgroundColor: theme.colors.loadingBackground,
    borderRadius: theme.scaledBorderRadius.medium,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.divider,
  },
  configText: {
    fontSize: theme.fonts.scaledSizes.medium,
    fontFamily: theme.fonts.family.monospace,
    color: theme.colors.text,
    lineHeight: theme.fonts.scaledSizes.medium * 1.5,
  },
  promptButtonText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecorationLine: 'underline',
  },
  closeModalButton: {
    marginVertical: theme.scaledSpacings.medium,
    padding: theme.scaledSpacings.medium,
    backgroundColor: theme.colors.bluePrimary,
    borderRadius: theme.scaledBorderRadius.medium,
    alignItems: 'center',
  },
  closeModalButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    textTransform: 'uppercase',
  },
  cardContainer: {
    padding: theme.scaledSpacings.medium,
    alignItems: 'center',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.colors.divider,
    marginBottom: theme.scaledSpacings.medium,
    backgroundColor: theme.colors.gray,
    borderRadius: theme.scaledBorderRadius.medium,
  },
  cardTopRow: {
    flexDirection: 'row',
    // alignItems: 'center',
    marginTop: theme.scaledSpacings.medium,
  },
  cardThumbnail: {
    width: 80,
    height: 80,
    borderRadius: 5,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.beige,
  },
  cardName: {
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.medium,
    color: theme.colors.redPrimary,
  },
  cardDescription: {
    fontSize: theme.fonts.scaledSizes.small,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.white,
    marginTop: theme.scaledSpacings.small,
  },
  styleContainer: {
    marginTop: theme.scaledSpacings.large,
    marginBottom: theme.scaledSpacings.medium,
  },
  styleTitle: {
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.gray,
    marginBottom: theme.scaledSpacings.small,
  },
  styleBox: {
    padding: theme.scaledSpacings.small,
    borderRadius: theme.scaledBorderRadius.medium,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: theme.colors.loadingBackground,
  },
  styleName: {
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.gray,
  },
  diversifiersContainer: {
    marginTop: theme.scaledSpacings.large,
  },
  diversifiersTitle: {
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.gray,
    marginBottom: theme.scaledSpacings.small,
  },
  diversifierItem: {
    padding: theme.scaledSpacings.small,
    borderRadius: theme.scaledBorderRadius.medium,
    borderWidth: StyleSheet.hairlineWidth,
    position: 'relative',
    marginVertical: theme.scaledSpacings.small,
    backgroundColor: theme.colors.loadingBackground,
  },
  evenItem: {
    backgroundColor: theme.colors.loadingBackground,
  },
  oddItem: {
    backgroundColor: theme.colors.loadingForeground,
  },
  searchIcon: {
    position: 'absolute',
    top: theme.scaledSpacings.small,
    right: theme.scaledSpacings.small,
    zIndex: 1,
  },
  diversifierType: {
    fontSize: theme.fonts.scaledSizes.medium,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.redPrimary,
    marginBottom: theme.scaledSpacings.xsmall,
  },
  diversifierName: {
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.bluePrimary,
    marginBottom: theme.scaledSpacings.xsmall,
  },
  diversifierDescription: {
    fontSize: theme.fonts.scaledSizes.medium,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.text,
  },
  divider: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: theme.colors.divider,
    width: '100%',
    marginVertical: theme.scaledSpacings.medium,
  },
  comment: {
    color: theme.colors.lightgray, // Color for comments
    fontFamily: theme.fonts.family.monospace,
    fontSize: theme.fonts.scaledSizes.medium,
  },
  key: {
    color: theme.colors.bluePrimary, // Color for YAML keys
    fontFamily: theme.fonts.family.monospace,
    fontSize: theme.fonts.scaledSizes.medium,
  },
  keyValue: {
    color: theme.colors.yellowPrimary, // Color for YAML values
    fontFamily: theme.fonts.family.monospace,
    fontSize: theme.fonts.scaledSizes.medium,
  },
  defaultText: {
    color: theme.colors.text, // Default color for unstyled text
    fontFamily: theme.fonts.family.monospace,
    fontSize: theme.fonts.scaledSizes.medium,
  },
  jsonViewer: {
    // padding: theme.scaledSpacings.medium,
    backgroundColor: theme.colors.loadingBackground,
    borderRadius: theme.scaledBorderRadius.medium,
    marginTop: theme.scaledSpacings.xxlarge,
  },
  promptButton: {
    padding: theme.scaledSpacings.medium,
    backgroundColor: theme.colors.primary,
    borderRadius: theme.scaledBorderRadius.medium,
    alignItems: 'center',
    marginVertical: theme.scaledSpacings.small, // Add spacing between buttons
  },
  modalContainer: {
    flex: 1,
    backgroundColor: theme.colors.beige,
    // marginTop: theme.scaledSpacings.large,
  },
  modalScrollContainer: {
    flex: 1,
    margin: theme.scaledSpacings.medium,
  },
  modalText: {
    fontFamily: theme.fonts.family.monospace,
    fontSize: theme.fonts.scaledSizes.medium,
    color: theme.colors.gray,
    lineHeight: theme.fonts.scaledSizes.medium * 1.5,
    paddingBottom: theme.scaledSpacings.medium, // Add padding at bottom for scrolling
  },
  modalTextNoWrap: {
    flexShrink: 0,
    width: 'auto',
    paddingRight: theme.scaledSpacings.xlarge, // Add padding for horizontal scroll
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end', // Align buttons to right
    alignItems: 'center',
    padding: theme.scaledSpacings.medium,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.colors.divider,
    gap: theme.scaledSpacings.medium, // Space between buttons
  },
  headerButton: {
    padding: theme.scaledSpacings.small,
    backgroundColor: theme.colors.primary,
    borderRadius: theme.scaledBorderRadius.small,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default CardDeveloperDetails;
