import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import {Ionicons, MaterialIcons} from '@expo/vector-icons';
import theme from "../../styles/theme";

const PlayPauseButton = ({ isPlaying, onPress, style, disabled = false}) => {
  return (
    <TouchableOpacity
      style={[
        styles.playPauseButton,
        style,
        disabled && styles.disabled,
      ]}
      onPress={onPress}
      hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
    >
      <MaterialIcons
        name={isPlaying ? "pause" : "play-arrow"}
        size={28}
        color={theme.colors.white}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  playPauseButton: {
    backgroundColor: theme.colors.partial_transparent_gray,
    // borderWidth: 1,
    // borderColor: theme.colors.white,
    borderRadius: 20,
    width: 35,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.5,
  }
});

export default PlayPauseButton;
