// planConfig.js
import iapConfig from './iap.config';

const planConfig = [
  {
    id: 'basic',
    title: 'Basic Plan',
    price: '$4.99',
    cadence: 'month',
    features: ['100 cards per month', 'premium styles', 'custom worlds'],
    productId: iapConfig.items.find(item => item.productId.includes('basic')).productId,
  },
  {
    id: 'standard',
    title: 'Standard Plan',
    price: '$9.99',
    cadence: 'month',
    features: ['300 cards per month', 'premium styles', 'custom worlds'],
    productId: iapConfig.items.find(item => item.productId.includes('standard')).productId,
  },
  {
    id: 'pro',
    title: 'Pro Plan',
    price: '$19.99',
    cadence: 'month',
    features: ['1000 cards per month', 'premium styles', 'custom worlds'],
    productId: iapConfig.items.find(item => item.productId.includes('pro')).productId,
  },
];

export default planConfig;
