import React, { createContext, useContext, useCallback } from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  fetchProfileData,
  updateTutorialSeen,
  updateDisclaimerAcknowledged,
  updateFirstCard,
  updateFirstRiff,
  updateSeenRiffInvite,
  updateProfileBoolFlag,
} from "../utils/api/userService";
import {shareContent} from "../utils/share";
import {AuthContext} from "./AuthContext";
import API_ENDPOINTS from "../apiEndpoints";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const userId = user?.attributes?.sub;
  const queryClient = useQueryClient();

  const invalidateProfileData = useCallback(() => {
    if (userId) {
      queryClient.invalidateQueries(['profileData', userId]);
      console.log(`Invalidated profile data for user ${userId}`);
    }
  }, [queryClient, userId]);

  const {
    data: profileData,
    isLoading,
    error,
    refetch,
  } = useQuery(['profileData', userId], fetchProfileData, {
    enabled: isAuthenticated && !!userId,
    refetchOnFocus: true,
  });

  const shareProfile = async () => {
    if (profileData && profileData.uuid) {
      await shareContent('profile', profileData.uuid);
    }
  };

  const tutorialMutation = useMutation(
    () => updateTutorialSeen(userId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['profileData', userId]);
        const previousProfileData = queryClient.getQueryData(['profileData', userId]);
        queryClient.setQueryData(['profileData', userId], old => ({
          ...old,
          tutorial_seen: true,
        }));
        return { previousProfileData };
      },
      onError: (err, newProfileData, context) => {
        queryClient.setQueryData(['profileData', userId], context.previousProfileData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['profileData', userId]);
      },
    }
  );

  const disclaimerMutation = useMutation(
    () => updateDisclaimerAcknowledged(userId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['profileData', userId]);
        const previousProfileData = queryClient.getQueryData(['profileData', userId]);
        queryClient.setQueryData(['profileData', userId], old => ({
          ...old,
          disclaimer_acknowledged: true,
        }));
        return { previousProfileData };
      },
      onError: (err, newProfileData, context) => {
        queryClient.setQueryData(['profileData', userId], context.previousProfileData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['profileData', userId]);
      },
    }
  );

  const createBoolMutation = (endpointKey, fieldName) => {
    return useMutation(
      () => updateProfileBoolFlag(API_ENDPOINTS[endpointKey], fieldName),
      {
        onMutate: async () => {
          await queryClient.cancelQueries(['profileData', userId]);
          const previousProfileData = queryClient.getQueryData(['profileData', userId]);
          queryClient.setQueryData(['profileData', userId], old => ({
            ...old,
            [fieldName]: true,
          }));
          return { previousProfileData };
        },
        onError: (err, newProfileData, context) => {
          queryClient.setQueryData(['profileData', userId], context.previousProfileData);
        },
        onSettled: () => {
          queryClient.invalidateQueries(['profileData', userId]);
        },
      }
    );
  };

  const createTooltipSeenMutation = createBoolMutation('UPDATE_CREATE_TOOLTIP_SEEN', 'create_tooltip_seen');
  const firstCardMutation = createBoolMutation('UPDATE_FIRST_CARD', 'first_card_created');
  const riffTooltipSeenMutation = createBoolMutation('UPDATE_RIFF_TOOLTIP_SEEN', 'riff_tooltip_seen');
  const firstRiffMutation = createBoolMutation('UPDATE_FIRST_RIFF', 'first_riff_created');
  const riffCreatedTooltipSeenMutation = createBoolMutation('UPDATE_RIFF_CREATED_TOOLTIP_SEEN', 'riff_created_tooltip_seen');

  const markTutorialComplete = async (onSuccessCallback) => {
    await tutorialMutation.mutateAsync();
    onSuccessCallback();
  };

  const markDisclaimerAcknowledged = async () => {
    await disclaimerMutation.mutateAsync();
  };

  const markCreateTooltipSeen = async () => {
    await createTooltipSeenMutation.mutateAsync();
  };

  const markFirstCard = async () => {
    await firstCardMutation.mutateAsync();
  };

  const markRiffTooltipSeen = async () => {
    await riffTooltipSeenMutation.mutateAsync();
  };

  const markFirstRiff = async () => {
    await firstRiffMutation.mutateAsync();
  };

  const markRiffCreatedTooltipSeen = async () => {
    await riffCreatedTooltipSeenMutation.mutateAsync();
  };

  const contextValue = {
    profileData,
    isLoading,
    error,
    refetchProfileData: refetch,
    shareProfile,
    markTutorialComplete,
    markDisclaimerAcknowledged,
    markCreateTooltipSeen,
    markRiffTooltipSeen,
    markFirstCard,
    markFirstRiff,
    markRiffCreatedTooltipSeen,
    invalidateProfileData,
  };

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => useContext(ProfileContext);
