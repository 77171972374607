import { useQuery } from 'react-query';
import { fetchCardAudio } from '../utils/api/cardsService';

export const useCardAudio = (cardUuid) => {
  return useQuery(
    ['cardAudio', cardUuid],
    () => fetchCardAudio(cardUuid),
    {
      enabled: !!cardUuid,
      // Poll every few seconds until audio is available
      refetchInterval: (data) => {
        if (data?.title_flavor_audio) {
          return false; // Stop polling once we have the audio
        }
        return 2000; // Poll every 2 seconds
      },
      refetchIntervalInBackground: true,
      staleTime: Infinity, // Once we have the audio, it won't change
    }
  );
};
