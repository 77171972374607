import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { theme } from '../../styles/theme';
import SvgRiff from "../../assets/images/Riff";
import RiffInlineText from "../RiffInlineText";
import { shareContent } from "../../utils/share";


const MadeFirstRiffModal = ({ isVisible, onClose }) => {

  const handleCollaborate = async () => {
    await shareContent('app', null);  // sharing app store link
    onClose();
  };

  return (
    <Modal visible={isVisible} transparent animationType="fade">
      <View style={styles.container}>
        <View style={styles.overlay} />

        <View style={styles.modalContent}>
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <MaterialIcons name="close" size={24} color={theme.colors.white} />
          </TouchableOpacity>

          <Text style={styles.title}>SHARE THE ADVENTURE</Text>

          <View style={styles.riffIconContainer}>
            <MaterialIcons name="person-add" size={32} color={theme.colors.lighterBlue} />
          </View>

          <Text style={styles.modalText}>
            Nice work on your first <RiffInlineText />! {"\n\n"}
            Invite your friends to join the fun. {"\n\n"}
            Build stories together by riffing on each other's cards.
          </Text>

          <TouchableOpacity style={styles.actionButton} onPress={handleCollaborate}>
            <Text style={styles.buttonText}>INVITE A FRIEND!</Text>
            <MaterialIcons
              name="ios-share"
              size={32}
              color={theme.colors.white}
            />
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: theme.colors.gray,
    marginHorizontal: 20,
    borderRadius: theme.borderRadius.large,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.bluePrimary,
    padding: 20,
    alignItems: 'center',
    zIndex: 1002,
  },
  title: {
    color: theme.colors.lighterBlue,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    marginBottom: 15,
    textAlign: 'center',
  },
  riffIconContainer: {
    marginBottom: 15,
    transform: [{ scale: 1.2 }]
  },
  modalText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.regular,
    marginBottom: 20,
    lineHeight: 24,
    // textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    padding: 5,
  },
  actionButton: {
    backgroundColor: theme.colors.bluePrimary,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: theme.borderRadius.medium,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center', // Add this
  },
  buttonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    marginRight: 10, // Add this for spacing between text and icon
  },
});

export default MadeFirstRiffModal;
